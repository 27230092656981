import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, json, RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import Root from './routes/Root';
import NotFound from './screens/Error';
import SportscapperList from './screens/SportscapperList';
import Subscriptions from './screens/Subscriptions';
import Sportscapper from './routes/Sportscapper';
import { ShoppingCartProvider } from './contexts/ShoppingCart';
import Checkout from './routes/Checkout';
import Order from './routes/Order';
import VerifyPhoneNumber from './routes/Authenticate';
import ManageSportscapper from './routes/ManageSportscapper';
import Register from './routes/Register';
import { datadogLogs } from '@datadog/browser-logs';
import { store } from './redux/store';
import AdminDashboard from './screens/AdminDashboard';
import Profile from './screens/Profile';
import { DiscordAuthentication } from './screens/DiscordAuthentication';
import { DiscordHowToGuide } from './routes/DiscordHowToGuide';
import ManageSportscapperOverview from './routes/ManageSportscapperOverview';
import ManageSportscapperSubscriptions from './routes/ManageSportscapperSubscriptions';
import ManageSportscapperPayments from './routes/ManageSportscapperPayments';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        path: '/',
        element: <SportscapperList />,
      },
      {
        path: '/subscriptions',
        element: <Subscriptions />,
      },
      {
        path: '/profile',
        element: <Profile />,
      },
      {
        path: '/profile/discord-auth',
        element: <DiscordAuthentication />,
      },
      {
        path: '/manage',
        element: <ManageSportscapper />,
        children: [
          {
            path: '/manage',
            element: <ManageSportscapperOverview />,
          },
          {
            path: '/manage/discord',
            element: <DiscordHowToGuide />,
          },
          {
            path: '/manage/subscribers',
            element: <ManageSportscapperSubscriptions />,
          },
          {
            path: '/manage/payments',
            element: <ManageSportscapperPayments />,
          },
        ],
      },
      {
        path: '/admin',
        element: <AdminDashboard />,
      },
    ],
  },
  {
    path: '/sportscapper/:username',
    element: <Sportscapper />,
    loader: async ({ params }) => {
      try {
        const formattedUsername = params?.username?.replace('@', '');
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/stripe/user?username=${formattedUsername}`,
        );
        if (response.ok) {
          const data = await response.json();
          return json({ capper: data });
        } else {
          throw new Error();
        }
      } catch (err) {
        datadogLogs.logger.error(`Error loading sportscapper page`, { params });
        throw new Response(`Error getting sportscapper`, { status: 404 });
      }
    },
    errorElement: <NotFound />,
  },
  {
    path: '/authenticate',
    element: <VerifyPhoneNumber />,
    errorElement: <NotFound />,
  },
  {
    path: '/checkout',
    element: <Checkout />,
    errorElement: <NotFound />,
  },
  {
    path: '/order',
    element: <Order />,
    errorElement: <NotFound />,
  },
  {
    path: '/register',
    element: <Register />,
    errorElement: <NotFound />,
  },
]);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ShoppingCartProvider>
        <RouterProvider router={router} />
      </ShoppingCartProvider>
    </Provider>
  </React.StrictMode>,
);
