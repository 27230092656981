import { ChangeEvent, useState } from 'react';
import { usePasswordLoginMutation } from '../redux/api/api';
import { setUser } from '../redux/slices/user.slice';
import { useAppDispatch } from '../redux/hooks';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { Circles } from 'react-loader-spinner';
import { Colors } from '../constants/Colors';

export default function PasswordVerification({
  onSuccess,
}: {
  onSuccess: (hasRegistered: boolean) => void;
}) {
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');

  const [mutate, { isLoading }] = usePasswordLoginMutation();

  const emailChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setError('');
    setEmail(e.target.value);
  };

  const passwordChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setError('');
    setPassword(e.target.value);
  };

  const submit = async () => {
    try {
      const res = await mutate({
        usernameOrEmail: email,
        password,
      }).unwrap();

      dispatch(setUser(res));
      onSuccess(true);
    } catch (err) {
      setError((err as { data: string }).data);
    }
  };

  return (
    <div className='flex flex-col justify-center items-center px-4 w-full md:w-4/5'>
      <b>Organization Login</b>
      <div className='my-3 flex flex-col my-2 w-full'>
        <b className='mb-1 text-sm'>Email</b>
        <input
          className='p-4 rounded-md border'
          onChange={emailChangeHandler}
          type={'text'}
          value={email}
          placeholder={'Organization Email'}
        />
      </div>

      <div className='my-3 flex flex-col w-full'>
        <b className='mb-1 text-sm'>Password</b>
        <div className='flex flex-row p-4 rounded-md border'>
          <input
            className='flex-1'
            onChange={passwordChangeHandler}
            type={showPassword ? 'text' : 'password'}
            placeholder={'Password'}
          />
          <button
            className='ml-2'
            onClick={() => {
              setShowPassword((prev) => !prev);
            }}
          >
            {showPassword ? <IoMdEyeOff size={24} /> : <IoMdEye size={24} />}
          </button>
        </div>
      </div>

      {error && <p className='mt-1 mb-2 text-errorRed'>{error}</p>}

      <button
        disabled={email.length === 0 || password.length === 0 || isLoading}
        className='disabled:opacity-75 disabled:hover:bg-gray-200 disabled:bg-gray-200 bg-primaryGold hover:bg-hoverPrimaryGold rounded-md p-4 disabled:text-gray-500 text-white w-full flex justify-center items-center mt-2'
        onClick={submit}
      >
        {isLoading ? (
          <div className='-m-7'>
            <Circles color={Colors.white} width={24} />
          </div>
        ) : (
          <p>Login</p>
        )}
      </button>
    </div>
  );
}
