import { useLoaderData } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';
import BackButton from '../components/BackButton';
import { Colors } from '../constants/Colors';
import { DisplayUser } from '../models/User';
import CapperPackages from '../components/CapperPackages';
import { LuShieldCheck } from 'react-icons/lu';

export default function Sportscapper() {
  const data = useLoaderData();
  const capper = (data as { capper: DisplayUser }).capper;

  return (
    <div className='flex flex-col px-3 py-4'>
      <BackButton to={'/'} />
      <div className='flex flex-col items-center h-full'>
        {false ? (
          <Circles color={Colors.primaryBlue} />
        ) : (
          <div className='flex flex-row items-center rounded-lg mb-4'>
            <div className='my-4 rounded-full'>
              <img
                className='rounded-full'
                src={capper?.avatar.contentUrl}
                alt=''
                width={90}
              />
            </div>
            <div className='flex flex-col m-4 w-fit h-fit'>
              <div className='flex flex-row items-center'>
                <LuShieldCheck size={20} color={Colors.primaryGold} />
                <p className='ml-1 text-sm md:text-md font-semibold text-primaryGold md:tracking-wide'>
                  Official Sportscapper
                </p>
              </div>
              <p className='text-lg font-semibold tracking-wide'>
                {capper?.displayName}
              </p>
              <p className='-mt-1 text-md tracking-tight text-primaryBlue'>
                @{capper?.username}
              </p>
            </div>
          </div>
        )}
        <div className='text-center text-4xl font-semibold my-4'>
          <p>Get my picks</p>
          <p>texted to you.</p>
        </div>

        {capper?.id ? <CapperPackages userId={capper?.id} /> : <></>}
      </div>
    </div>
  );
}
