import { useNavigate } from 'react-router-dom';

export default function LoginButton() {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate('/authenticate')}
      className='w-4/5 mx-4 flex flex-col justify-center items-center bg-primaryBlue hover:bg-hoverPrimaryBlue text-white p-2 text-sm rounded-md'
    >
      <p>Log in</p>
    </button>
  );
}
