import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { StripePrice } from '../../models/SportscapperPackage';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { useShoppingCart } from '../../contexts/ShoppingCart';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import { trackEvent } from '../../util/datadog';
import CarouselCard from './CarouselCard';

interface CarouselProps {
  items: StripePrice[];
}

export default function SportscapperPackageCarousel(props: CarouselProps) {
  const carouselLength = props.items.length;

  const { addItemToCart } = useShoppingCart();
  const navigate = useNavigate();

  const [centerCardIndex, setCenterCardIndex] = useState(0);
  const [leftCardIndex, setLeftCardIndex] = useState<number>(0);
  const [rightCardIndex, setRightCardIndex] = useState<number>(0);

  const [showSideCards, setShowSideCards] = useState(true);

  const { width } = useWindowDimensions();

  useLayoutEffect(() => {
    setLeftCardIndex(carouselLength - 1);
    if (carouselLength === 1) {
      setRightCardIndex(0);
    } else {
      setRightCardIndex(1);
    }
  }, [carouselLength]);

  useEffect(() => {
    if (width >= 1200) {
      setShowSideCards(true);
    } else {
      setShowSideCards(false);
    }
  }, [width]);

  const handleCheckout = useCallback(
    (item: StripePrice) => {
      addItemToCart(item);
      trackEvent('checkout-item-start', { item });
      navigate('/checkout');
    },
    [addItemToCart, navigate],
  );

  const calculateLeftAndRightCardIndices = (nextCenter: number) => {
    setCenterCardIndex(nextCenter);
    setLeftCardIndex(nextCenter - 1 < 0 ? carouselLength - 1 : nextCenter - 1);
    setRightCardIndex(nextCenter + 1 >= carouselLength ? 0 : nextCenter + 1);
  };

  const handleArrow = (direction: 'left' | 'right') => {
    calculateLeftAndRightCardIndices(
      direction === 'left' ? leftCardIndex : rightCardIndex,
    );
  };

  const handleBottomButton = (itemIndex: number) => {
    calculateLeftAndRightCardIndices(itemIndex);
  };

  const leftCard = useMemo(() => {
    return (
      <div className='flex-1 mx-4 h-[300px]'>
        <CarouselCard
          item={props.items[leftCardIndex]}
          handleCheckout={handleCheckout}
        />
      </div>
    );
  }, [props.items, leftCardIndex, handleCheckout]);

  const centerCard = useMemo(() => {
    return (
      <div className='flex-1 h-[350px] min-w-[200px] mx-4'>
        <CarouselCard
          item={props.items[centerCardIndex]}
          handleCheckout={handleCheckout}
          primary={true}
        />
      </div>
    );
  }, [props.items, centerCardIndex, handleCheckout]);

  const rightCard = useMemo(() => {
    return (
      <div className='flex-1 mx-4 h-[300px]'>
        <CarouselCard
          item={props.items[rightCardIndex]}
          handleCheckout={handleCheckout}
        />
      </div>
    );
  }, [handleCheckout, props.items, rightCardIndex]);

  if (carouselLength < 1) {
    return <p>No packages</p>;
  }

  return (
    <div className='w-[95%] h-full rounded-lg bg-slate-100 my-5 py-5 px-3 md:px-5 flex flex-row items-center'>
      <button
        className='w-fit h-fit md:mr-10'
        onClick={() => handleArrow('left')}
      >
        <FaArrowLeft size={24} />
      </button>

      <div className='flex-1 flex flex-col'>
        <div className='flex flex-row justify-center items-center'>
          {showSideCards && leftCard}
          {centerCard}
          {showSideCards && rightCard}
        </div>
        <div className='mt-4 flex-1 flex flex-row justify-center items-center'>
          {props.items.map((item, idx) => (
            <button key={idx} onClick={() => handleBottomButton(idx)}>
              <div
                className={`bg-${centerCardIndex === idx ? 'primaryBlue border-primaryBlue' : 'transparent'} rounded-full shadow p-1 mx-2 z-10`}
              />
            </button>
          ))}
        </div>
      </div>

      <button
        className='w-fit h-fit md:ml-10'
        onClick={() => handleArrow('right')}
      >
        <FaArrowRight size={24} />
      </button>
    </div>
  );
}
