import Grid from '../components/Grid';
import SubscriptionIcon from '../assets/subscriptions.svg';
import UserCardButton from '../components/UserCardButton';
import UserCard from '../components/UserCard';
import { DisplayUserWithPackage } from '../models/User';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Circles } from 'react-loader-spinner';
import { Colors } from '../constants/Colors';
import {
  btbApi,
  useCancelSubscriptionMutation,
  useGetSubscriptionsQuery,
} from '../redux/api/api';
import { useAppSelector } from '../redux/hooks';
import { format } from 'date-fns';

export default function Subscriptions() {
  const [capperToCancel, setCapperToCancel] = useState<{
    id: string;
    user: DisplayUserWithPackage;
  } | null>(null);

  const { id } = useAppSelector((state) => state.user);
  const navigate = useNavigate();

  const { data, isLoading } = useGetSubscriptionsQuery();
  const [mutateAsync, { isLoading: subscriptionIsCanceling }] =
    useCancelSubscriptionMutation();

  const handleCardCancelSubscriptionClick = (user: {
    id: string;
    user: DisplayUserWithPackage;
  }) => {
    setCapperToCancel(user);
  };

  const doNotCancel = () => {
    setCapperToCancel(null);
    window.location.reload();
  };

  const cancel = async () => {
    if (capperToCancel) {
      await mutateAsync(capperToCancel.id).unwrap();
    }

    btbApi.util.invalidateTags(['subscriptions']);
    setCapperToCancel(null);
    window.location.reload();
  };

  useEffect(() => {
    if (!id) {
      navigate('/authenticate');
    }
  }, [navigate, id]);

  const activeSubscriptions = useMemo(() => {
    if (!data) {
      return [];
    }
    return data?.filter((d) => d.autoRenew);
  }, [data]);

  const inactiveSubscriptions = useMemo(() => {
    if (!data) {
      return [];
    }
    return data?.filter((d) => !d.autoRenew);
  }, [data]);

  return (
    <>
      <div className='flex flex-col mx-3 mb-3'>
        <div className='fixed h-[45px] bg-white w-full'>
          <div className='flex flex-row items-center'>
            <div className='my-3 mr-3'>
              <img src={SubscriptionIcon} alt='' width={24} />
            </div>
            <p>Your Subscriptions</p>
          </div>
        </div>
        <div className='h-[45px]' />
        <div className='flex flex-col items-center md:items-start'>
          {isLoading ? (
            <div className='h-full w-3/4 flex flex-col justify-start items-center mt-20'>
              <Circles height={80} color={Colors.primaryBlue} width={80} />
            </div>
          ) : data?.length === 0 ? (
            <p className='font-light text-md py-5'>No subscriptions.</p>
          ) : (
            <>
              <p className='font-semibold'>Active Subscriptions</p>
              <p className='text-sm mb-4'>
                These will renew on the listed date.
              </p>
              <Grid>
                {activeSubscriptions?.map((s) => {
                  const formattedRenewDate = format(
                    s.renewalDate,
                    'yyyy-MM-dd',
                  );
                  const formattedStartDate = format(s.startDate, 'yyyy-MM-dd');
                  const bodyText = s.autoRenew
                    ? `Subscription will renew on ${formattedRenewDate}`
                    : `You will lose access on ${formattedRenewDate}`;
                  return (
                    <UserCard
                      key={s.id}
                      user={s.user}
                      body={() => (
                        <div className='flex flex-col mb-3 mt-1 text-center text-xs'>
                          <p className='mb-1'>{`$${s.price / 100} - Cycle started ${formattedStartDate}`}</p>
                          <p className='mb-1 font-semibold'>{bodyText}</p>
                        </div>
                      )}
                      bottomButton={() => (
                        <UserCardButton
                          title={
                            s.autoRenew
                              ? 'End Subscription'
                              : 'Subscription Canceled'
                          }
                          colors={
                            'border border-red-600 bg-white hover:bg-red-100 disabled:border-gray-300 disabled:hover:bg-transparent disabled:text-gray-300 text-red-600'
                          }
                          textClass='text-center font-light text-sm'
                          disabled={!s.autoRenew}
                          onClick={() => handleCardCancelSubscriptionClick(s)}
                        />
                      )}
                    />
                  );
                })}
              </Grid>
              <p className='font-semibold -mt-4'>Canceled Subscriptions</p>
              <p className='text-sm mb-4'>
                You will have access to these picks until the listed date.
              </p>
              <Grid>
                {inactiveSubscriptions?.map((s) => {
                  const formattedRenewDate = format(
                    s.renewalDate,
                    'yyyy-MM-dd',
                  );
                  const formattedStartDate = format(s.startDate, 'yyyy-MM-dd');
                  const bodyText = s.autoRenew
                    ? `Subscription will renew on ${formattedRenewDate}`
                    : `You will lose access on ${formattedRenewDate}`;
                  return (
                    <UserCard
                      key={s.id}
                      user={s.user}
                      body={() => (
                        <div className='flex flex-col mb-3 mt-1 text-center text-xs'>
                          <p className='mb-1'>{`$${s.price / 100} - Cycle started ${formattedStartDate}`}</p>
                          <p className='mb-1 font-semibold'>{bodyText}</p>
                        </div>
                      )}
                      bottomButton={() => (
                        <UserCardButton
                          title={
                            s.autoRenew
                              ? 'End Subscription'
                              : 'Subscription Canceled'
                          }
                          colors={
                            'border border-red-600 bg-white hover:bg-red-100 disabled:border-gray-300 disabled:hover:bg-transparent disabled:text-gray-300 text-red-600'
                          }
                          textClass='text-center font-light text-sm'
                          disabled={!s.autoRenew}
                          onClick={() => handleCardCancelSubscriptionClick(s)}
                        />
                      )}
                    />
                  );
                })}
              </Grid>
            </>
          )}
        </div>
      </div>

      {capperToCancel ? (
        <div className='z-50 fixed top-0 bg-black/25 h-screen w-screen flex flex-col justify-center items-center'>
          <div className='bg-white rounded-lg shadow p-10 w-4/5 md:w-2/5 h-fit flex flex-col items-center'>
            <p className='text-xl text-center font-bold mb-2'>
              Are you sure you want to cancel?
            </p>
            <p className='text-center'>
              Your subscription will not be charged upon renewal. Do you wish to
              proceed?
            </p>
            <div className='flex flex-row mt-6 w-full'>
              <button
                onClick={doNotCancel}
                disabled={subscriptionIsCanceling}
                className='mx-1 w-1/2 p-3 rounded-md bg-primaryBlue hover:bg-hoverPrimaryBlue'
              >
                <p className='text-sm text-white'>No</p>
              </button>
              <button
                onClick={cancel}
                disabled={subscriptionIsCanceling}
                className='mx-1 w-1/2 border border-errorRed hover:bg-errorRed p-3 rounded-md text-errorRed hover:text-white'
              >
                <p className='text-sm'>Cancel my subscription</p>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
