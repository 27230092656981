import { useEffect } from 'react';
import { useNavigate, useRouteError } from 'react-router-dom';
import { trackEvent } from '../util/datadog';

export default function NotFound() {
  const navigate = useNavigate();
  const error = useRouteError();

  useEffect(() => {
    trackEvent('show-error-page', { error });
  }, [error]);

  return (
    <div className='flex flex-col justify-center items-center mt-10'>
      <p className='text-xl'>Oops!</p>
      <p className='text-md'>
        There seems to have been an error. Sorry about that.
      </p>
      <button
        className='mt-10 bg-primaryBlue text-white p-3 w-[100px] rounded-md'
        onClick={() => navigate('/')}
      >
        Home
      </button>
    </div>
  );
}
