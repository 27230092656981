import { useGetUserAuthenticatedQuery } from '../redux/api/api';

export default function AvatarCircle({
  userId,
  size,
}: {
  userId: string;
  size?: number;
}) {
  const { data } = useGetUserAuthenticatedQuery(userId);

  return (
    <div className={`my-4 rounded-full`}>
      {!data?.avatar?.contentUrl ? (
        <div className='bg-slate-100' />
      ) : (
        <img
          className='rounded-full'
          src={data?.avatar.contentUrl}
          alt=''
          width={`${size ?? 32}px`}
        />
      )}
    </div>
  );
}
