import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js';
import { useShoppingCart } from '../contexts/ShoppingCart';
import { useEffect } from 'react';
import { stripePromise } from '../constants/Stripe';
import CheckoutLayout from '../components/CheckoutLayout/CheckoutLayout';
import { useNavigate } from 'react-router-dom';
import CheckoutUser from '../components/CheckoutLayout/CheckoutUser';
import { useAppSelector } from '../redux/hooks';
import {
  useGetUserUnauthenticatedQuery,
  useRequestStripeClientSecretMutation,
} from '../redux/api/api';

export default function SportscapperPackageCheckout() {
  const { cart, clearCart } = useShoppingCart();
  const navigate = useNavigate();

  if (!cart) {
    throw new Error('No cart item');
  }

  const { phoneNumber } = useAppSelector((state) => state.user);
  const [mutate, { data, error }] = useRequestStripeClientSecretMutation();
  const priceId = cart.stripeId;

  const { data: capper } = useGetUserUnauthenticatedQuery(cart.capperUserId);

  useEffect(() => {
    if (phoneNumber && priceId) {
      mutate(priceId).unwrap();
    }
  }, [mutate, phoneNumber, priceId]);

  useEffect(() => {
    if (!phoneNumber) {
      navigate('/authenticate');
    }
  }, [phoneNumber, navigate]);

  return (
    <CheckoutLayout
      tabComponent={() => {
        return (
          <div className='text-white flex flex-col items-center'>
            <CheckoutUser />
          </div>
        );
      }}
    >
      <div className='w-full flex-1 mt-20 md:mt-0'>
        {(error as { status: string })?.status === 'subscription_exists' ? (
          <div className='flex-1 p-20 text-center flex flex-col'>
            <p>
              You're already subscribed to{' '}
              {capper?.username ? (
                <span className='text-primaryBlue'>@{capper?.username}</span>
              ) : (
                'this sportscapper'
              )}
              .
            </p>
            <button
              className='mt-4 rounded-md bg-primaryBlue text-white py-2 px-4 text-sm font-light'
              onClick={() => {
                clearCart();
                navigate('/subscriptions');
              }}
            >
              <p>View Subscriptions</p>
            </button>
            <button
              className='mt-4 rounded-md bg-primaryBlue text-white py-2 px-4 text-sm font-light'
              onClick={() => {
                clearCart();
                navigate('/');
              }}
            >
              <p>Return Home</p>
            </button>
          </div>
        ) : data?.clientSecret ? (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret: data?.clientSecret as string }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        ) : (
          <></>
        )}
      </div>
    </CheckoutLayout>
  );
}
