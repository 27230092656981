import { Circles } from 'react-loader-spinner';
import { Colors } from '../constants/Colors';
import SportscapperPackageCarousel from './Carousel/SportscapperPackageCarousel';
import { StripePrice } from '../models/SportscapperPackage';
import { useGetSportscapperPackagesQuery } from '../redux/api/api';

export default function CapperPackages({ userId }: { userId: string }) {
  const packageQuery = useGetSportscapperPackagesQuery(userId);

  return packageQuery.isLoading || packageQuery.data?.length === 0 ? (
    <div className='h-full flex flex-col justify-start items-center mt-20'>
      <Circles height={80} color={Colors.primaryBlue} width={80} />
    </div>
  ) : (
    <SportscapperPackageCarousel items={packageQuery.data as StripePrice[]} />
  );
}
