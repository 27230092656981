import { Circles } from 'react-loader-spinner';
import { useGetSubscribersQuery } from '../redux/api/api';
import { useAppSelector } from '../redux/hooks';
import SubscriberGrid from '../components/SubscriberGrid';
import { DisplayUser, DisplayUserWithSubscriptionInfo } from '../models/User';
import { Colors } from '../constants/Colors';

export default function CapperSubscribers() {
  const userState = useAppSelector((state) => state.user);
  const { data: subscribers, isLoading } = useGetSubscribersQuery(
    userState.id as string,
  );

  return isLoading || !subscribers ? (
    <div className='w-full flex flex-col items-center'>
      <Circles color={Colors.primaryBlue} />
    </div>
  ) : (
    <SubscriberGrid
      capper={userState as DisplayUser}
      subscribers={subscribers as DisplayUserWithSubscriptionInfo[]}
    />
  );
}
