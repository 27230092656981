import { useState } from 'react';

export default function UserCardButton({
  onClick,
  colors,
  title,
  textClass,
  afterPressContent,
  disabled,
}: {
  onClick: () => void;
  colors: string;
  title: string;
  textClass: string;
  afterPressContent?: any;
  disabled?: boolean;
}) {
  const [buttonDisabled, setButtonDisabled] = useState(disabled ?? false);
  return (
    <button
      onClick={() => {
        setButtonDisabled(true);
        onClick();
      }}
      disabled={buttonDisabled}
      className={`w-full flex p-3 rounded-md justify-center ${colors}`}
    >
      {afterPressContent && disabled ? (
        afterPressContent
      ) : (
        <p className={textClass}>{title}</p>
      )}
    </button>
  );
}
