import { useMemo } from 'react';
import { useGetStripeOnboardingStatusQuery } from '../redux/api/api';
import ManageSportscapperOnboarding from '../components/ManageSportscapperOnboarding';

export default function ManageSportscapperOverview() {
  const stripeAccountOnboardingQuery = useGetStripeOnboardingStatusQuery();
  const showOnboarding = useMemo(
    () => (stripeAccountOnboardingQuery.data ?? 'inactive') === 'inactive',
    [stripeAccountOnboardingQuery.data],
  );

  return (
    <div className='flex flex-col'>
      <p>Welcome to the Sportscapper Management Portal!</p>
      {showOnboarding && !stripeAccountOnboardingQuery.isLoading && (
        <ManageSportscapperOnboarding />
      )}
    </div>
  );
}
