import { PropsWithChildren } from 'react';

export default function Grid({ children }: PropsWithChildren) {
  return (
    <div
      className={`flex-1 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-3 mb-10`}
    >
      {children}
    </div>
  );
}
