import { Colors } from '../../constants/Colors';
import { useShoppingCart } from '../../contexts/ShoppingCart';
import { Circles } from 'react-loader-spinner';
import { useGetUserUnauthenticatedQuery } from '../../redux/api/api';

export default function CheckoutUser() {
  const { cart } = useShoppingCart();
  const userQuery = useGetUserUnauthenticatedQuery(
    cart?.capperUserId as string,
  );

  return (
    <>
      {userQuery.isLoading ? (
        <div className='my-20'>
          <Circles color={Colors.white} width={72} />
        </div>
      ) : (
        <div className='flex flex-col mt-5 p-3'>
          <div className='flex flex-row text-lg'>
            <p className='text-slate-200'>Subscribe to</p>
            <p className='ml-1 text-white'>@{userQuery.data?.username}</p>
          </div>
          <div className='flex flex-row my-6 justify-start items-center'>
            <p className='text-4xl'>
              ${((cart?.priceInCents ?? 0) / 100).toFixed(2)}
            </p>
            {cart?.interval && (
              <div className='flex flex-col ml-2 text-sm text-slate-200'>
                <p>every</p>
                <p>
                  {cart?.intervalCount === 1 ? '' : cart.intervalCount}{' '}
                  {cart?.interval}
                  {(cart?.intervalCount ?? 0) > 1 ? 's' : ''}
                </p>
              </div>
            )}
          </div>

          <div className='flex flex-row my-3'>
            <div className='w-1/4'>
              <img
                className='w-full rounded-full shadow-lg'
                src={userQuery.data?.avatar.contentUrl}
                alt=''
              />
            </div>
            <div className='w-3/4 ml-5 flex flex-col'>
              <div className='flex flex-row items-center my-1'>
                <p className='font-semibold'>{userQuery.data?.displayName}</p>
                <div className='flex-1' />
                <p>${`${((cart?.priceInCents ?? 0) / 100).toFixed(2)}`}</p>
              </div>

              <p className='text-sm mb-4 text-slate-200'>{cart?.nickname}</p>

              <hr />

              <div className='flex flex-row items-center my-4'>
                <p className='font-semibold'>Subtotal</p>
                <div className='flex-1' />
                <p>${`${((cart?.priceInCents ?? 0) / 100).toFixed(2)}`}</p>
              </div>

              <hr />

              <div className='flex flex-row items-center my-4'>
                <p className='font-semibold'>Total due today</p>
                <div className='flex-1' />
                <p>${`${((cart?.priceInCents ?? 0) / 100).toFixed(2)}`}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
