import {
  ConnectComponentsProvider,
  ConnectPayments,
  ConnectPayouts,
} from '@stripe/react-connect-js';
import { useEffect, useState } from 'react';
import { setUser } from '../redux/slices/user.slice';
import { loadConnectAndInitialize } from '@stripe/connect-js';
import { useCreateAccountSessionMutation } from '../redux/api/api';
import { useAppDispatch } from '../redux/hooks';

export default function ManageSportscapperPayments() {
  const dispatch = useAppDispatch();
  const [getClientSecret] = useCreateAccountSessionMutation();

  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      const res = await getClientSecret().unwrap();
      return res.clientSecret;
    };

    return loadConnectAndInitialize({
      publishableKey: process.env.REACT_APP_STRIPE_PUBLISHING_KEY as string,
      fetchClientSecret,
    });
  });

  useEffect(() => {
    if (stripeConnectInstance) {
      dispatch(setUser({ stripeConnectInstance }));
    }
  }, [dispatch, stripeConnectInstance]);

  return stripeConnectInstance !== undefined ? (
    <div className='bg-white flex flex-col mx-3 mb-3 w-full'>
      <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
        <div className='z-1 mt-4 h-max-4/5 px-4 font-semibold text-lg'>
          <p>Payouts</p>
          <ConnectPayouts />
          <div className='py-2' />
          <p>Payments</p>
          <ConnectPayments />
        </div>
      </ConnectComponentsProvider>
    </div>
  ) : (
    <></>
  );
}
