import { Link, useNavigate } from 'react-router-dom';
import {
  useGetStripeOnboardingStatusQuery,
  useGetUserAuthenticatedQuery,
} from '../redux/api/api';
import { useAppSelector } from '../redux/hooks';
import { SelfDisplayUser } from '../models/User';
import { useMemo } from 'react';
import { LinkDiscordButton } from '../components/LinkDiscordButton';

export function DiscordHowToGuide() {
  const navigate = useNavigate();
  const { id } = useAppSelector((state) => state.user);

  const { data, isLoading } = useGetUserAuthenticatedQuery(id as string);
  const stripeAccountOnboardingQuery = useGetStripeOnboardingStatusQuery();

  const userHasLinkedDiscord = useMemo(
    () => (data as SelfDisplayUser)?.discordId,
    [data],
  );

  const userHasSignedUpWithStripe = useMemo(
    () => (stripeAccountOnboardingQuery.data ?? 'active') === 'active',
    [stripeAccountOnboardingQuery.data],
  );

  return (
    <div className='z-1 mt-1 h-max-4/5'>
      <div className='flex flex-col pb-20'>
        <p className='text-xl font-semibold mb-4'>
          Beat the Book Discord Bot Setup Guide
        </p>

        <p className='text-lg font-semibold'>
          What does the Beat the Book Discord Bot do?
        </p>
        <ul className='list-disc my-2 ml-6'>
          <li>
            Manages roles for any subscribers and non-subscribers inside your
            Discord server
          </li>
          <li>Automatically reposts your premium and/or public picks</li>
        </ul>

        {isLoading ? (
          <></>
        ) : (
          <div className='flex flex-col justify-center mb-2'>
            <p
              className={`mr-2 ${userHasLinkedDiscord ? 'text-successGreen' : 'text-errorRed'}`}
            >
              You <b>{userHasLinkedDiscord ? 'have' : 'have not'}</b> linked
              your Discord account.
            </p>
            <p
              className={`mr-2 ${userHasSignedUpWithStripe ? 'text-successGreen' : 'text-errorRed'}`}
            >
              You <b>{userHasSignedUpWithStripe ? 'have' : 'have not'}</b> made
              a Stripe account.
            </p>

            {userHasLinkedDiscord && userHasSignedUpWithStripe && (
              <p className='mr-2 text-successGreen'>
                You're ready to set up the Discord Bot!
              </p>
            )}

            {!userHasLinkedDiscord && (
              <div className='flex flex-col w-fit gap-2'>
                <p>
                  Link your Discord account through the button below to
                  continue:
                </p>
                <LinkDiscordButton />
              </div>
            )}
            {!userHasSignedUpWithStripe && (
              <div className='flex flex-col w-fit gap-2'>
                <Link to={'/manage'} className='text-primaryBlue underline'>
                  Sign up with Stripe to continue setting up the Discord Bot.
                </Link>
              </div>
            )}
          </div>
        )}

        <p className='text-lg font-semibold'>Bot installation</p>
        <p className='my-2 text-sm font-italic'>
          If you are already the owner of a Discord server, you can start from
          Step #4.
        </p>
        <ol className='list-decimal ml-6 mb-4'>
          <li>
            Create a{' '}
            <a
              className='text-primaryBlue underline'
              href='https://discord.com/'
            >
              Discord
            </a>{' '}
            account, download the app, and log in.
          </li>
          <li>
            Link your Discord and Beat the Book accounts through the{' '}
            <button onClick={() => navigate('/profile')}>
              <span className='text-primaryBlue underline'>link above!</span>
            </button>
          </li>
          <li>
            <a
              className='text-primaryBlue underline'
              href='https://discord.com/blog/starting-your-first-discord-server'
            >
              Create
            </a>{' '}
            a Discord server.
          </li>
          <li>
            Add the Beat the Book Discord Bot to your server by clicking{' '}
            <a
              className='text-primaryBlue underline'
              href={process.env.REACT_APP_DISCORD_BOT_INSTALL_LINK}
            >
              here.
            </a>
          </li>

          <div className='-ml-4 my-4 p-4 rounded-md shadow-md shadow-inner w-full bg-slate-100 flex flex-col justify-center items-center'>
            <img
              className='my-1 rounded-md w-3/4 md:w-1/2'
              src={require('../assets/discord-setup/authorizeBot.png')}
              alt=''
            />
            <ol className='list-decimal ml-2 mt-1 text-sm'>
              <li>Select the server you just created.</li>
              <li>Click "Authorize".</li>
            </ol>
          </div>

          <li>
            Your Discord server now has the Beat the Book Discord Bot installed!
            Note that it won't manage anything for you until it's initialized in
            the next section, "Automatic role management".
          </li>
        </ol>

        <p className='text-lg font-semibold'>Automatic role management</p>
        <p>
          One of the primary features of the Beat the Book Discord Bot is to
          automatically manage your Discord server's roles for you. To read more
          about Discord roles,{' '}
          <a
            className='text-primaryBlue underline'
            href='https://support.discord.com/hc/en-us/articles/214836687-Role-Management-101'
          >
            click here
          </a>
          . To initialize this feature:
        </p>
        <ol className='list-decimal ml-6 mb-4'>
          <li>
            Complete the "Bot installation" section before starting this
            section.
          </li>
          <li>
            Create the role that will be managed by the Beat the Book Discord
            Bot.
          </li>
          <div className='-ml-4 my-4 p-4 rounded-md shadow-md shadow-inner w-full bg-slate-100 flex flex-col justify-center items-center'>
            <img
              className='my-1 rounded-md w-3/4 md:w-1/2'
              src={require('../assets/discord-setup/serverSettings.png')}
              alt=''
            />
            <ol className='list-none ml-2 mt-1 text-sm'>
              <li>
                a. Click on your server's name in the top left corner (here,
                it's "Server").
              </li>
              <li>
                b. Click "Server Settings" to bring you to the settings menu.
              </li>
            </ol>

            <img
              className='mt-3 mb-1 rounded-md w-3/4 md:w-1/2'
              src={require('../assets/discord-setup/serverSettingsMenu.png')}
              alt=''
            />
            <ol className='list-none ml-2 mt-1 text-sm'>
              <li>c. Click "Roles" to open the roles menu.</li>
              <li>d. Click "Create Role".</li>
            </ol>

            <img
              className='mt-3 mb-1 rounded-md w-3/4 md:w-1/2'
              src={require('../assets/discord-setup/createRole.png')}
              alt=''
            />
            <ol className='list-none ml-2 mt-1 text-sm'>
              <li>
                e. Name your new role. Note that this is the <b>only</b> role
                that the Bot will interact with. For the sake of simplicity, we
                recommend you name the new role "btb-premium".
              </li>
              <li>
                f. Feel free to change any of the other display properties of
                the role.
              </li>
              <li>g. Save your changes, and the role is created!</li>
            </ol>
          </div>

          <li>
            Now that the role is created, you can initialize the bot. In any
            text channel, run{' '}
            <code className='text-sm'>/btbinitialize role @btb-premium</code>.
            This will tell the Bot to manage the "btb-premium" role for your
            subscribers.
          </li>
          <div className='-ml-4 my-4 p-4 rounded-md shadow-md shadow-inner w-full bg-slate-100 flex flex-col justify-center items-center'>
            <img
              className='mt-1 mb-1 rounded-md w-3/4 md:w-1/2'
              src={require('../assets/discord-setup/btbInitializeCommand.png')}
              alt=''
            />
          </div>

          <li>
            One of the common Beat the Book Discord Bot use cases is to use the
            automatic role management feature to allow your subscribers to view
            channels that non-subscribers can't see. To set this up:
          </li>
          <div className='-ml-4 my-4 p-4 rounded-md shadow-md shadow-inner w-full bg-slate-100 flex flex-col justify-center items-center'>
            <img
              className='mt-1 mb-1 rounded-md w-3/4 md:w-1/2'
              src={require('../assets/discord-setup/channelView.png')}
              alt=''
            />
            <ol className='list-none ml-2 mt-1 text-sm'>
              <li>
                a. Create a new channel by pressing the "+" sign to the right of
                "Text Channels".{' '}
                <a
                  className='text-primaryBlue underline'
                  href='https://support.discord.com/hc/en-us/articles/4412085582359-Text-Channels-Text-Chat-In-Voice-Channels'
                >
                  See more information on creating a new text channel.
                </a>
              </li>
            </ol>

            <img
              className='mt-3 mb-1 rounded-md w-3/4 md:w-1/2'
              src={require('../assets/discord-setup/createChannel.png')}
              alt=''
            />
            <ol className='list-none ml-2 mt-1 text-sm'>
              <li>
                b. Name the channel, and{' '}
                <b>toggle the "Private Channel" slider</b>.
              </li>
            </ol>

            <img
              className='mt-3 mb-1 rounded-md w-3/4 md:w-1/2'
              src={require('../assets/discord-setup/addRoles.png')}
              alt=''
            />
            <ol className='list-none ml-2 mt-1 text-sm'>
              <li>
                c. Add the new role created in Step #2. This will only allow
                users with the "btb-premium" role to view the contents of this
                channel.
              </li>
              <li>
                d. You've created a role-managed channel! Because the Beat the
                Book Discord Bot will manage this "btb-premium" role for you,
                your subscribers will be automatically added and removed from
                this channel according to their subscription status. Keep in
                mind that any manual adjustments to this role's membership will
                not be detected by the bot, potentially giving an unsubscribed
                user permanent access to a channel.{' '}
                <b>
                  We highly recommend that you never manually assign users to
                  this role.
                </b>
              </li>
            </ol>
          </div>
        </ol>

        <p className='text-lg font-semibold'>Automatic reposting</p>
        <p>
          Another feature of the Beat the Book Discord Bot is to repost your
          picks. To initialize this feature:
        </p>
        <ol className='list-decimal ml-6 mb-4'>
          <li>
            Complete the "Bot installation" and "Automatic role management"
            sections before starting this section.
          </li>
          <li>
            In the role-managed text channel you created in the last section
            ("#premium-picks"), run{' '}
            <code className='text-sm'>/btbembed posttype premium</code> to allow{' '}
            <b>only</b> your premium picks to show up in this channel. Note that
            this command configures reposting for only this channel, and
            rerunning the command will update the channel's reposting
            preferences. Other options are:
          </li>
          <ul className='ml-4 list-disc'>
            <li>"all", which reposts premium and public picks</li>
            <li>
              "public", which reposts <b>only</b> public picks
            </li>
            <li>"none", which stops the reposting of picks in the channel</li>
          </ul>
          <div className='-ml-4 my-4 p-4 rounded-md shadow-md shadow-inner w-full bg-slate-100 flex flex-col justify-center items-center'>
            <img
              className='mt-1 mb-1 rounded-md w-3/4 md:w-1/2'
              src={require('../assets/discord-setup/btbEmbedCommand.png')}
              alt=''
            />
          </div>

          <li>You've set up automatic reposting!</li>
          <div className='-ml-4 my-4 p-4 rounded-md shadow-md shadow-inner w-full bg-slate-100 flex flex-col justify-center items-center'>
            <img
              className='mt-1 mb-1 rounded-md w-3/4 md:w-1/2'
              src={require('../assets/discord-setup/embed.png')}
              alt=''
            />
          </div>
        </ol>
      </div>
    </div>
  );
}
