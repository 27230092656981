export function LinkDiscordButton() {
  return (
    <a href={process.env.REACT_APP_DISCORD_OAUTH_URL ?? '#'}>
      <div className='w-fit py-2 px-4 rounded-md bg-discordBlurple'>
        <img
          src={require('../assets/discord-logo-white.png')}
          alt=''
          width={100}
        />
      </div>
    </a>
  );
}
