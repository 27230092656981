import { HiOutlineBanknotes } from 'react-icons/hi2';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../redux/hooks';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { UserRole } from '../models/User';
import { useGetStripeOnboardingStatusQuery } from '../redux/api/api';
import NavigationLink from '../components/NavigationLink';
import DiscordIcon from '../assets/discord-mark-black.svg';
import SubscriptionIcon from '../assets/subscriptions.svg';
import { IoHomeOutline } from 'react-icons/io5';

export default function ManageSportscapper() {
  const navigate = useNavigate();
  const location = useLocation();

  const { id, userRoles } = useAppSelector((state) => state.user);
  const [selectedPage, setSelectedPage] = useState(location.pathname);

  useEffect(() => {
    if (!id || !userRoles?.includes(UserRole.handicapper)) {
      navigate('/');
    }
  }, [id, navigate, userRoles]);

  const stripeAccountOnboardingQuery = useGetStripeOnboardingStatusQuery();

  const showOnboarding = useMemo(
    () => (stripeAccountOnboardingQuery.data ?? 'inactive') === 'inactive',
    [stripeAccountOnboardingQuery.data],
  );

  return (
    <div className='flex flex-col mx-3 mb-3'>
      <div className='z-10 fixed h-[45px] bg-white w-full'>
        <div className='flex flex-row items-center'>
          <div className='my-3 mr-3'>
            <HiOutlineBanknotes size={24} />
          </div>
          <p>Manage Packages</p>
        </div>
      </div>
      <div className='h-[45px]' />
      <div className='w-full flex flex-col justify-start items-start'>
        {stripeAccountOnboardingQuery.isLoading ? (
          <div className='h-[45px]' />
        ) : (
          <div className='fixed bg-white flex flex-row justify-start items-start gap-1 w-full -mb-2 -mt-1'>
            <NavigationLink
              icon={<IoHomeOutline size={20} />}
              route={'/manage'}
              name='Home'
              selected={selectedPage === '/manage'}
              setSelectedPage={setSelectedPage}
            />
            <NavigationLink
              icon={<img src={SubscriptionIcon} alt='' width={24} />}
              route={'/manage/subscribers'}
              name='Subscribers'
              selected={selectedPage === '/manage/subscribers'}
              setSelectedPage={setSelectedPage}
            />
            {!showOnboarding && (
              <NavigationLink
                icon={<HiOutlineBanknotes size={24} />}
                route={'/manage/payments'}
                name='Payments'
                selected={selectedPage === '/manage/payments'}
                setSelectedPage={setSelectedPage}
              />
            )}
            <NavigationLink
              icon={<img src={DiscordIcon} alt='' width={24} />}
              route={'/manage/discord'}
              name='Discord Bot Setup'
              selected={selectedPage === '/manage/discord'}
              setSelectedPage={setSelectedPage}
            />
          </div>
        )}
      </div>
      <div className='h-[60px]' />
      <Outlet />
    </div>
  );
}
