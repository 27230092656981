export const Colors = {
  primaryGold: '#b27828',
  hoverPrimaryGold: '#8f601f',
  primaryBlue: '#2070d3',
  hoverPrimaryBlue: '#144887',
  lightGold: '#f6f0e7',
  white: '#ffffff',
  black: '#000000',
  errorRed: '#dc2626',
  successGreen: '#388e3c',
  discordBlurple: '#5865f2',
};
