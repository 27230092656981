export function formatE164NumberToDisplay(phone: string) {
  if (phone.length < 10) {
    return '';
  }
  const countryCode = phone.slice(0, phone.length - 10);
  const firstDigits = phone.slice(phone.length - 10, phone.length - 7);
  const middleDigits = phone.slice(phone.length - 7, phone.length - 4);
  const lastDigits = phone.slice(phone.length - 4, phone.length);

  return `${countryCode} (${firstDigits}) ${middleDigits}-${lastDigits}`;
}
