import WhiteLogo from '../assets/whiteLogo.svg';

export default function Logo({
  light,
  size,
  text,
}: {
  light?: boolean;
  size?: number;
  text?: boolean;
}) {
  const realSize = size ?? 45;
  let realText = true;
  if (text === false) {
    realText = false;
  }
  const image = light ? (
    <img src={WhiteLogo} alt='' width={realSize} />
  ) : (
    <img src={require('../assets/logo.png')} alt='' width={realSize} />
  );

  return (
    <div className='flex flex-row items-center'>
      {image}
      {realText && (
        <div className='ml-3'>
          <p
            className={`text-${light ? 'white' : 'black'} text-md font-semibold tracking-wide`}
          >
            Beat the Book
          </p>
        </div>
      )}
    </div>
  );
}
