import { useAppSelector } from '../../redux/hooks';
import LogoutButton from './LogoutButton';
import LoginButton from './LoginButton';
import { useMemo } from 'react';

export default function LoginManager() {
  const { id, displayName, username } = useAppSelector((state) => state.user);

  const body = useMemo(() => {
    return id ? <LogoutButton /> : <LoginButton />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, displayName, username]);

  return (
    <div className='flex flex-col justify-center items-center text-primaryBlue text-sm mb-6'>
      {body}
    </div>
  );
}
