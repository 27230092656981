import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DiscordUser } from '../models/User';
import { Circles } from 'react-loader-spinner';
import { Colors } from '../constants/Colors';
import Logo from '../components/Logo';
import { usePatchUserMutation } from '../redux/api/api';
import { useAppSelector } from '../redux/hooks';

export function DiscordAuthentication() {
  const navigate = useNavigate();
  const { id } = useAppSelector((state) => state.user);

  const fragment = new URLSearchParams(window.location.hash.slice(1));
  const [accessToken, tokenType] = [
    fragment.get('access_token'),
    fragment.get('token_type'),
  ];
  const [discordUser, setDiscordUser] = useState<DiscordUser | null>(null);
  const [discordLinkingError, setDiscordLinkingError] =
    useState<boolean>(false);

  const [mutate] = usePatchUserMutation();

  useEffect(() => {
    const getId = async () => {
      if (!accessToken) {
        navigate('/');
      }

      try {
        const res = await fetch('https://discord.com/api/users/@me', {
          headers: {
            authorization: `${tokenType} ${accessToken}`,
          },
        });

        let user: DiscordUser;
        if (res.ok) {
          user = await res.json();
        } else {
          throw new Error();
        }

        await mutate({
          userId: id as string,
          body: { discordId: user.id.toString() },
        }).unwrap();
        setDiscordUser(user);
      } catch {
        setDiscordLinkingError(true);
      }
    };

    if (!discordUser) {
      getId();
    }
  }, [accessToken, discordUser, id, mutate, navigate, tokenType]);

  return (
    <div className='flex flex-col h-screen w-screen justify-center items-center bg-slate-900'>
      <div className='flex flex-col justify-center items-center w-4/5 md:w-2/5 h-1/2 p-10 bg-discordBlurple rounded-lg'>
        {!discordUser && !discordLinkingError ? (
          <Circles color={Colors.white} width={64} />
        ) : (
          <div className='flex flex-col justify-center items-center'>
            {!discordLinkingError ? (
              <>
                <div className='flex flex-row gap-10 justify-center items-center'>
                  <Logo light={true} size={100} text={false} />
                  <p className='text-white text-2xl font-black'>X</p>
                  <img
                    src={require('../assets/discord-mark-white.png')}
                    alt=''
                    className='object-scale-down w-32'
                  />
                </div>
                <p className='text-white font-bold text-lg p-3'>
                  Your BTB account is now linked with Discord!
                </p>
              </>
            ) : (
              <p className='text-white text-center font-bold text-lg p-3'>
                There was an error connecting your Discord account. Please try
                again later.
              </p>
            )}

            <button
              className='bg-white py-2 px-4 mt-2 rounded-md'
              onClick={() => navigate('/')}
            >
              <p className='text-discordBlurple text-sm font-bold'>
                Return home
              </p>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
