import { FaChevronLeft } from 'react-icons/fa6';
import Logo from './Logo';
import { Path, useNavigate } from 'react-router-dom';
import { Colors } from '../constants/Colors';

export default function BackButton({
  light,
  to,
}: {
  light?: boolean;
  to: string | Partial<Path>;
}) {
  const navigate = useNavigate();

  return (
    <button className='my-3 w-fit' onClick={() => navigate(to)}>
      <div className='flex flex-row items-center'>
        <FaChevronLeft
          color={light ? Colors.white : Colors.black}
          className='mr-3'
        />
        <Logo light={!!light} />
      </div>
    </button>
  );
}
