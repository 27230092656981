import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { UserRole } from '../../models/User';
import { jwtDecode } from 'jwt-decode';
import { datadogLogs } from '@datadog/browser-logs';
import { StripeConnectInstance } from '@stripe/connect-js';

export interface UserState {
  id?: string;
  username?: string;
  displayName?: string;
  accessToken?: string;
  refreshToken?: string;
  userRoles?: UserRole[];
  phoneNumber?: string;
  stripeConnectInstance?: StripeConnectInstance;
}

const initialState: UserState = {
  id: sessionStorage.getItem('userId') ?? undefined,
  username: sessionStorage.getItem('username') ?? undefined,
  userRoles:
    JSON.parse(sessionStorage.getItem('userRoles') ?? '[]') ?? undefined,
  phoneNumber: sessionStorage.getItem('phoneNumber') ?? undefined,
  displayName: sessionStorage.getItem('displayName') ?? undefined,
  accessToken: sessionStorage.getItem('accessToken') ?? undefined,
  refreshToken: sessionStorage.getItem('refreshToken') ?? undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state: UserState, action: PayloadAction<UserState>) => {
      const {
        id,
        username,
        phoneNumber,
        displayName,
        accessToken,
        refreshToken,
      } = action.payload;

      if (accessToken && refreshToken) {
        const roles = (jwtDecode(accessToken) as { userRoles: UserRole[] })
          .userRoles;

        action.payload = { ...action.payload, userRoles: roles };

        sessionStorage.setItem('userRoles', JSON.stringify(roles));
        sessionStorage.setItem('accessToken', accessToken);
        sessionStorage.setItem('refreshToken', refreshToken);
      }

      if (id) {
        sessionStorage.setItem('userId', id);
        datadogLogs.setUserProperty('id', id);
      }

      if (phoneNumber) {
        sessionStorage.setItem('phoneNumber', phoneNumber);
      }

      if (username) {
        sessionStorage.setItem('username', username);
        datadogLogs.setUserProperty('username', username);
      }

      if (displayName) {
        sessionStorage.setItem('displayName', displayName);
      }

      return {
        ...state,
        ...action.payload,
      };
    },
    unsetUser: (state) => {
      sessionStorage.clear();
      datadogLogs.clearUser();
      state.stripeConnectInstance?.logout();

      return {};
    },
  },
});

export const { setUser, unsetUser } = userSlice.actions;
export default userSlice.reducer;
