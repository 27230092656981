import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { eventEmitter } from '../util/emitter';
import { trackEvent } from '../util/datadog';

export default function NavigationLink({
  icon,
  name,
  route,
  selected,
  setSelectedPage,
}: {
  icon: JSX.Element;
  name: string;
  route: string;
  selected: boolean; // TODO use a context for this probably
  setSelectedPage: (name: string) => void;
}) {
  const containerStyle = useMemo(
    () =>
      `flex flex-row items-center m-4 md:m-2 px-2 py-3 rounded-md${selected ? ' bg-slate-100' : ''}`,
    [selected],
  );

  return (
    <Link
      className={containerStyle}
      to={`${route}`}
      onClick={() => {
        setSelectedPage(route);
        eventEmitter.emit('navigationLinkPress');
        trackEvent(`nav-route-${route}`);
      }}
    >
      {icon}
      <div className='ml-2'>
        <p className='text-md md:text-sm'>{name}</p>
      </div>
    </Link>
  );
}
