import { StripePrice } from '../../models/SportscapperPackage';
import UserCardButton from '../UserCardButton';

interface CarouselCardProps {
  item: StripePrice;
  handleCheckout: (item: StripePrice) => void;
  primary?: boolean;
}

export default function CarouselCard(props: CarouselCardProps): JSX.Element {
  let { interval, intervalCount, freeTrialDays, nickname, priceInCents } =
    props.item;

  return (
    <div className='flex-1 bg-white rounded-lg h-full p-5 shadow flex flex-col justify-between items-center'>
      <p className='my-3 text-2xl font-semibold text-center'>{nickname}</p>
      <div className='h-full flex flex-col justify-center items-center'>
        <div className='flex flex-row -ml-2 w-fit'>
          <p className='mt-2 mr-1'>$</p>
          <p className='text-[48px]'>{priceInCents / 100}</p>
          {interval !== null && intervalCount !== null && (
            <p className='text-xl font-bold pt-8 ml-1'>
              /{' '}
              {intervalCount === 1 ? interval : `${intervalCount} ${interval}s`}
            </p>
          )}
        </div>
        {freeTrialDays && (
          <div className='-mt-1 bg-green-100 flex flex-row py-1 px-4 rounded-full'>
            <p className='text-xs font-light text-green-800 text-center'>
              {freeTrialDays} Day Free Trial
            </p>
          </div>
        )}
      </div>
      {props.primary ? (
        <div className='flex flex-col bg-blue-50 w-full mt-2 p-3 rounded-md justify-between'>
          <UserCardButton
            onClick={() => props.handleCheckout(props.item)}
            colors={'bg-primaryBlue'}
            title={'Checkout'}
            textClass={'text-white'}
          />
        </div>
      ) : (
        <div className='mt-3'>
          <UserCardButton
            onClick={() => props.handleCheckout(props.item)}
            colors={'border border-primaryBlue rounded-md'}
            title={'Checkout'}
            textClass={'text-primaryBlue'}
          />
        </div>
      )}
      <p className='mt-2 text-[8px] text-center text-slate-400'>
        By clicking checkout, you consent to this person having your name and
        email to dispute any issues. Refunds are provided solely at the
        discretion of Beat the Book.
      </p>
    </div>
  );
}
