import NavigationDrawer from '../components/NavigationDrawer';
import { Outlet } from 'react-router-dom';
import { useWindowDimensions } from '../hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import { IoMenuOutline } from 'react-icons/io5';
import { IoMdClose } from 'react-icons/io';
import { eventEmitter } from '../util/emitter';

export default function Root() {
  const { width } = useWindowDimensions();

  const [showDrawer, setShowDrawer] = useState(false);

  useEffect(() => {
    function closeNav() {
      setShowDrawer(false);
    }

    eventEmitter.on('navigationLinkPress', closeNav);

    return () => {
      eventEmitter.off('navigationLinkPress', closeNav);
    };
  }, []);

  return width >= 768 ? (
    <div className='flex flex-row w-screen z-0'>
      <div
        className={`z-10 fixed w-[200px] min-w-[200px] flex flex-col h-screen pl-4 py-4`}
      >
        <NavigationDrawer />
      </div>
      <div className='w-[200px] min-w-[200px]' />
      <div className='flex-1'>
        <Outlet />
      </div>
    </div>
  ) : (
    <div className='w-screen'>
      <div className='flex flex-col'>
        <div className='z-10 h-[60px] fixed flex items-center w-full bg-white'>
          <button className='mt-2 ml-5' onClick={() => setShowDrawer(true)}>
            <IoMenuOutline size={30} />
          </button>
        </div>
        <div className='h-[60px]' />
        <Outlet />
      </div>
      {showDrawer && (
        <div className='mt-6 z-20 fixed top-0 left-0 bg-white z-10 w-full h-screen flex flex-row justify-between items-start'>
          <div
            className={`w-[300px] min-w-[300px] flex flex-col h-screen pl-4 py-4`}
          >
            <NavigationDrawer />
          </div>
          <button className='m-5' onClick={() => setShowDrawer(false)}>
            <IoMdClose size={30} />
          </button>
        </div>
      )}
    </div>
  );
}
