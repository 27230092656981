import Grid from '../components/Grid';
import Shield from '../assets/shield-tick.svg';
import UserCardButton from '../components/UserCardButton';
import UserCard from '../components/UserCard';
import { DisplayUserWithPackage } from '../models/User';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../constants/Colors';
import { Circles } from 'react-loader-spinner';
import { useGetSportscapperListQuery } from '../redux/api/api';

export default function SportscapperList() {
  const navigate = useNavigate();

  const { data, isLoading } = useGetSportscapperListQuery();

  const handleSubscribeClick = (user: DisplayUserWithPackage) => {
    return navigate(`/sportscapper/@${user.username}`);
  };

  return (
    <>
      <div className='flex flex-col mx-3 mb-3'>
        <div className='fixed h-[45px] bg-white w-full'>
          <div className='flex flex-row items-center'>
            <div className='my-3 mr-3'>
              <img src={Shield} alt='' width={24} />
            </div>
            <p>Sportscappers on Beat the Book</p>
          </div>
        </div>
        <div className='h-[45px]' />
        <div className={'flex flex-col items-center md:items-start'}>
          {isLoading ? (
            <div className='h-full w-3/4 flex flex-col justify-start items-center mt-20'>
              <Circles height={80} color={Colors.primaryBlue} width={80} />
            </div>
          ) : (
            <Grid>
              {data?.map((s) => (
                <UserCard
                  key={s.id}
                  user={s}
                  body={() => (
                    <div className='mb-4 mt-1'>
                      <p className='text-sm'>
                        Starting at $
                        {(s as DisplayUserWithPackage).lowestPriceInCents / 100}
                      </p>
                    </div>
                  )}
                  bottomButton={() => (
                    <UserCardButton
                      title={'Subscribe'}
                      colors={
                        'bg-primaryGold hover:bg-hoverPrimaryGold border-primaryGold hover:border-hoverPrimaryGold disabled:bg-slate-400 disabled:border-slate-400'
                      }
                      textClass='text-center font-light text-sm text-white'
                      onClick={() => handleSubscribeClick(s)}
                      afterPressContent={
                        <Circles height={20} color={Colors.white} width={20} />
                      }
                    />
                  )}
                />
              ))}
            </Grid>
          )}
        </div>
      </div>
    </>
  );
}
