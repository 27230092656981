import { useMemo, useState } from 'react';
import CheckoutLayout from '../components/CheckoutLayout/CheckoutLayout';
import { formatE164NumberToDisplay } from '../util/phoneNumber';
import { RegisterRequest } from '../models/Register';
import { useNavigate } from 'react-router-dom';
import { useShoppingCart } from '../contexts/ShoppingCart';
import { Colors } from '../constants/Colors';
import { Circles } from 'react-loader-spinner';
import { useAppSelector } from '../redux/hooks';
import { useRegisterMutation } from '../redux/api/api';

export default function Register() {
  const { phoneNumber } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const { cart } = useShoppingCart();

  const initialRegistrationFields = {
    username: '',
    phoneNumber,
    email: '',
    over18: false,
  };
  const [registrationFields, setRegistrationFields] = useState(
    initialRegistrationFields,
  );

  const [registerValidationErrors, setRegistrationValidationErrors] = useState<{
    username?: string;
    email?: string;
  }>({});

  const [mutateAsync, { isLoading: mutationLoading }] = useRegisterMutation();

  const registerFormCompleted = useMemo(() => {
    const anyValidationErrors =
      registerValidationErrors.username || registerValidationErrors.email;

    return !anyValidationErrors && registrationFields.over18;
  }, [registrationFields, registerValidationErrors]);

  const onUsernameChange = (e: { target: { value: string } }) => {
    setRegistrationFields((oldState) => ({
      ...oldState,
      username: e.target.value,
    }));

    if (e.target.value.length < 4 || e.target.value.length > 16) {
      setRegistrationValidationErrors((oldState) => ({
        ...oldState,
        username:
          'Username must be at least 4 characters, but less than or equal to 16 characters.',
      }));
    } else if (!/^[a-z_.]+$/.test(e.target.value.toLowerCase())) {
      setRegistrationValidationErrors((oldState) => ({
        ...oldState,
        username: 'Username can only contain letters, underscores, or periods.',
      }));
    } else {
      setRegistrationValidationErrors((oldState) => ({
        ...oldState,
        username: undefined,
      }));
    }
  };

  const onEmailChange = (e: { target: { value: string } }) => {
    setRegistrationFields((oldState) => ({
      ...oldState,
      email: e.target.value,
    }));

    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(e.target.value.toLowerCase())) {
      setRegistrationValidationErrors((oldState) => ({
        ...oldState,
        email: 'Must provide a valid email.',
      }));
    } else {
      setRegistrationValidationErrors((oldState) => ({
        ...oldState,
        email: undefined,
      }));
    }
  };

  const submitForm = async () => {
    const { over18, ...registrationBody } = registrationFields;

    let valid = true;
    let res: any;
    try {
      res = await mutateAsync(registrationBody as RegisterRequest).unwrap();
    } catch (err) {
      res = err;
      valid = false;
    }

    if (valid) {
      if (cart) {
        navigate('/checkout');
      } else {
        navigate('/');
      }
    } else {
      if (res.originalStatus === 401) {
        // phone code timed out
        navigate('/authenticate', {
          state: {
            error:
              'Phone number code timed out, please re-verify your phone number to continue.',
          },
        });
      } else if (res.originalStatus === 400) {
        setRegistrationValidationErrors((oldState) => ({
          username: res?.data?.username ?? oldState.username,
          email: res?.data?.email ?? oldState.email,
        }));
      } else {
        setRegistrationValidationErrors((oldState) => ({
          ...oldState,
          general: 'Error registering. Please try again later.',
        }));
      }
    }
  };

  return (
    <div className='flex justify-center items-center w-screen h-screen'>
      <CheckoutLayout
        tabComponent={() => {
          return (
            <div className='flex flex-col items-center text-white text-lg mt-10 px-5'>
              <p>Register with Beat the Book</p>
            </div>
          );
        }}
      >
        <div className='h-full flex flex-col justify-center items-start px-4 w-full md:w-4/5'>
          <p className='font-bold mt-2 mb-1'>Phone Number</p>
          <div className='border rounded-md p-2 mb-1 bg-slate-100 w-full text-slate-500'>
            <p>
              {formatE164NumberToDisplay(
                registrationFields.phoneNumber as string,
              )}
            </p>
          </div>

          <p className='font-bold mt-2 mb-1'>Username</p>
          <input
            className='border rounded-md p-2 mb-1 bg-slate-100 w-full'
            placeholder='sampleUsername'
            onChange={onUsernameChange}
            value={registrationFields.username}
          />
          {registerValidationErrors.username && (
            <p className='mb-1 text-errorRed'>
              {registerValidationErrors.username}
            </p>
          )}

          <p className='font-bold mt-2 mb-1'>Email</p>
          <input
            className='border rounded-md p-2 mb-1 bg-slate-100 w-full'
            placeholder='sample@beatthebookinc.com'
            onChange={onEmailChange}
            value={registrationFields.email}
          />
          {registerValidationErrors.email && (
            <p className='mb-1 text-errorRed'>
              {registerValidationErrors.email}
            </p>
          )}

          <div className='w-full flex flex-row justify-center items-center mt-2'>
            <input
              id='over18'
              type={'checkbox'}
              onChange={(e) =>
                setRegistrationFields((oldState) => ({
                  ...oldState,
                  over18: e.target.checked,
                }))
              }
            />
            <label className='ml-2' htmlFor='over18'>
              I am over 18 years old
            </label>
          </div>

          <div className='w-full flex flex-row justify-center items-center mt-2 text-center'>
            <p>
              By registering, you accept Beat the Book's{' '}
              <a
                className='text-primaryBlue'
                href='https://www.beatthebookinc.com/tos'
              >
                terms of service
              </a>{' '}
              and{' '}
              <a
                className='text-primaryBlue'
                href='https://www.beatthebookinc.com/privacy-policy'
              >
                privacy policy
              </a>
              .
            </p>
          </div>

          <button
            disabled={!registerFormCompleted || mutationLoading}
            className='disabled:opacity-75 disabled:hover:bg-gray-200 disabled:bg-gray-200 bg-primaryGold hover:bg-hoverPrimaryGold rounded-md disabled:text-gray-500 text-white w-full flex justify-center items-center mt-4'
            onClick={submitForm}
          >
            {mutationLoading ? (
              <div className='-m-3'>
                <Circles width={20} color={Colors.white} />
              </div>
            ) : (
              <div className='p-4'>
                <p>Register</p>
              </div>
            )}
          </button>
        </div>
      </CheckoutLayout>
    </div>
  );
}
