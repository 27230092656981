import { DisplayUser, DisplayUserWithPackage } from '../models/User';

export default function UserCard({
  user,
  body,
  bottomButton,
}: {
  user: DisplayUserWithPackage | DisplayUser;
  body: () => JSX.Element;
  bottomButton?: () => JSX.Element;
}) {
  return (
    <div className='w-[330px] md:w-[260px] flex flex-col items-center px-4 pt-2 pb-4 border rounded-lg shadow'>
      <div className='my-4 rounded-full w-24'>
        <img
          className='rounded-full'
          src={user.avatar.contentUrl}
          alt={`${user.username}'s avatar`}
        />
      </div>
      <div>
        <p className='font-semibold text-lg'>{user.displayName}</p>
      </div>
      {body ? body() : <div className='p-2' />}
      {bottomButton && <div className='w-full'>{bottomButton()}</div>}
    </div>
  );
}
