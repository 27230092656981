import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  DisplayUser,
  DisplayUserWithPackage,
  DisplayUserWithSubscriptionInfo,
  PatchUserRequest,
  SelfDisplayUser,
} from '../../models/User';
import { RegisterRequest } from '../../models/Register';
import { format } from 'date-fns';
import { RootState } from '../store';
import { v4 } from 'uuid';
import { setUser, unsetUser } from '../slices/user.slice';
import { datadogLogs } from '@datadog/browser-logs';
import { StripePrice } from '../../models/SportscapperPackage';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, api) => {
    const traceId = v4();
    const { user } = api.getState() as RootState;

    headers.set('BTB-Tracing-ID', traceId);

    if (user.accessToken) {
      headers.set('Authorization', `Bearer ${user.accessToken}`);
    }

    return headers;
  },
});

// TODO: break the endpoints up into separate files?
export const btbApi = createApi({
  reducerPath: 'api',
  baseQuery: async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.meta?.response?.status === 401) {
      const { user } = api.getState() as RootState;
      const refreshResult = await baseQuery(
        {
          url: '/api/v1/login/refresh',
          method: 'post',
          body: {
            refreshToken: user.refreshToken,
          },
        },
        api,
        extraOptions,
      );
      if (refreshResult.data) {
        datadogLogs.logger.info('Refresh attempt succeeded');
        api.dispatch(setUser(refreshResult.data));
        result = await baseQuery(args, api, extraOptions);
      } else {
        datadogLogs.logger.warn('Refresh attempt failed');
        api.dispatch(unsetUser());
      }
    }

    const logString = `[network] ${result.meta?.response?.status} ${result.meta?.request.method.toUpperCase()} ${result.meta?.request.url}`;
    const details = {
      body: result.data,
      error: result.error,
      btbTraceId: result.meta?.request.headers.get('btb-tracing-id'),
    };

    if (result.error) {
      datadogLogs.logger.warn(logString, details);
    } else {
      datadogLogs.logger.info(logString, details);
    }

    return result;
  },
  tagTypes: ['subscriptions', 'userUnauthenticated'],

  endpoints: (builder) => ({
    // queries
    getUserAuthenticated: builder.query<DisplayUser | null, string>({
      query: (userId) => `/api/v1/user/${userId}`,
      transformErrorResponse: (error) => {
        if (error.status === 404) {
          return null;
        }
        throw error;
      },
      async onQueryStarted(userId, api) {
        const { user } = api.getState() as RootState;
        const { data } = await api.queryFulfilled;
        if (userId === user.id && data) {
          api.dispatch(setUser({ ...data }));
        }
      },
    }),
    getUserUnauthenticated: builder.query<DisplayUser | null, string>({
      query: (userId) => `/api/v1/stripe/user?targetId=${userId}`,
      transformErrorResponse: (error) => {
        if (error.status === 404) {
          return null;
        }
        throw error;
      },
      providesTags: (res) => [{ type: 'userUnauthenticated', id: res?.id }],
    }),
    getProfileShareLink: builder.query<{ link: string } | undefined, string>({
      query: (userId) => `/api/v2/profile/share?targetId=${userId}`,
    }),
    getStripeCheckoutSessionDetails: builder.query<
      {
        sessionId: string;
        status: string;
      },
      string
    >({
      query: (sessionId) => `/api/v1/stripe/checkout-session/${sessionId}`,
      transformErrorResponse: (error) => {
        if (error.status === 404) {
          return { status: 'not found' };
        }
        throw error;
      },
    }),
    getStripeOnboardingStatus: builder.query<'active' | 'inactive', void>({
      query: () => '/api/v1/stripe/user/express-account/status',
    }),
    getSportscapperList: builder.query<DisplayUserWithPackage[], void>({
      query: () => '/api/v1/stripe/user',
    }),
    getSportscapperPackages: builder.query<StripePrice[], string>({
      query: (capperId) => `/api/v1/stripe/capper-package?targetId=${capperId}`,
    }),
    getSubscriptions: builder.query<DisplayUserWithSubscriptionInfo[], void>({
      query: () => '/api/v1/stripe/capper-subscription',
      transformErrorResponse: (error) => {
        if (error.status === 404) {
          return [];
        }
        throw error;
      },
      providesTags: ['subscriptions'],
    }),
    getSubscribers: builder.query<DisplayUserWithSubscriptionInfo[], string>({
      query: (userId) =>
        `/api/v1/stripe/capper-package/user?targetId=${userId}`,
    }),
    getSubscribersAdmin: builder.query<
      {
        capper: DisplayUser;
        subscriptions: DisplayUserWithSubscriptionInfo[];
      }[],
      void
    >({
      query: () => '/api/v1/admin/stripe/capper-package/user',
    }),

    // mutations
    register: builder.mutation<
      {
        id: string;
        phoneNumber: string;
        accessToken: string;
        refreshToken: string;
      },
      RegisterRequest
    >({
      query: (registrationRequest) => ({
        url: `/api/v1/register/phone`,
        method: 'post',
        body: {
          ...registrationRequest,
          timezoneOffset: format(new Date(), 'xxxxx'),
        },
      }),
      async onQueryStarted(_, api) {
        try {
          const { data } = await api.queryFulfilled;
          if (data) {
            api.dispatch(setUser(data));
          }
        } catch (err) {
          datadogLogs.logger.info(`User error during registration: ${err}`);
        }
      },
    }),
    verifyPhoneNumberCode: builder.mutation<
      {
        id?: string;
        phoneNumber?: string;
        userHasRegistered: boolean;
        accessToken?: string;
        refreshToken?: string;
      },
      { code: string; phoneNumber: string }
    >({
      query: ({ code, phoneNumber }) => ({
        url: '/api/v1/login/phone-verification-token',
        method: 'post',
        body: {
          code,
          phoneNumber,
        },
      }),
      async onQueryStarted(_, api) {
        const { data } = await api.queryFulfilled;
        if (data) {
          api.dispatch(setUser(data));
        }
      },
    }),
    verifyPhoneNumberRequest: builder.mutation<void, string>({
      query: (phoneNumber) => ({
        url: '/api/v1/login/text-message-code',
        method: 'post',
        body: {
          phoneNumber,
        },
      }),
      async onQueryStarted(phoneNumber, api) {
        await api.queryFulfilled;
        api.dispatch(setUser({ phoneNumber }));
      },
    }),
    requestStripeClientSecret: builder.mutation<
      { clientSecret: string; sessionId: string },
      string
    >({
      query: (priceId) => ({
        url: '/api/v1/stripe/capper-subscription/checkout',
        method: 'post',
        body: {
          priceId,
        },
      }),
      transformErrorResponse: (error) => {
        if ((error as { originalStatus: number }).originalStatus === 400) {
          return { status: 'subscription_exists' };
        }
      },
    }),
    cancelSubscription: builder.mutation<void, string>({
      query: (capperId) => ({
        url: `/api/v1/stripe/capper-subscription/${capperId}`,
        method: 'delete',
      }),
    }),
    createAccountSession: builder.mutation<{ clientSecret: string }, void>({
      query: () => ({
        url: '/api/v1/stripe/user/account-session',
        method: 'post',
      }),
    }),
    createExpressAccount: builder.mutation<{ url: string }, void>({
      query: () => ({
        url: '/api/v1/stripe/user/express-account',
        method: 'post',
      }),
    }),
    passwordLogin: builder.mutation<
      {
        id: string;
        accessToken: string;
        refreshToken: string;
      },
      { usernameOrEmail: string; password: string }
    >({
      query: (loginRequest) => ({
        url: '/api/v1/login',
        method: 'post',
        body: {
          ...loginRequest,
          timezoneOffset: format(new Date(), 'xxxxx'),
        },
      }),
      async onQueryStarted(_, api) {
        const { data } = await api.queryFulfilled;
        if (data) {
          api.dispatch(setUser(data));
        }
      },
    }),
    patchUser: builder.mutation<
      SelfDisplayUser,
      { userId: string; body: PatchUserRequest }
    >({
      query: ({ userId, body }) => ({
        url: `/api/v1/user/${userId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result) => [
        { type: 'userUnauthenticated', id: result?.id },
      ],
    }),
  }),
});

export const {
  useGetUserAuthenticatedQuery,
  useGetUserUnauthenticatedQuery,
  useGetProfileShareLinkQuery,
  useGetSportscapperListQuery,
  useGetSportscapperPackagesQuery,
  useGetStripeCheckoutSessionDetailsQuery,
  useGetStripeOnboardingStatusQuery,
  useGetSubscriptionsQuery,
  useGetSubscribersQuery,
  useGetSubscribersAdminQuery,

  useRegisterMutation,
  useCreateAccountSessionMutation,
  useVerifyPhoneNumberCodeMutation,
  useVerifyPhoneNumberRequestMutation,
  useCancelSubscriptionMutation,
  useRequestStripeClientSecretMutation,
  useCreateExpressAccountMutation,
  usePasswordLoginMutation,
  usePatchUserMutation,
} = btbApi;
