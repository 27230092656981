import { format } from 'date-fns';
import Grid from './Grid';
import { DisplayUser, DisplayUserWithSubscriptionInfo } from '../models/User';
import UserCard from './UserCard';

export default function SubscriberGrid({
  capper,
  subscribers,
  admin,
}: {
  capper: DisplayUser;
  subscribers: DisplayUserWithSubscriptionInfo[];
  admin?: boolean;
}): JSX.Element {
  let filteredSubscribers = subscribers;
  if (!admin) {
    filteredSubscribers = filteredSubscribers.filter((s) => s.user);
  }

  return (
    <>
      <div className='flex flex-row mb-2'>
        <a
          href={`/sportscapper/@${capper.username}`}
          className='font-medium text-primaryBlue'
        >
          @{capper.username}
        </a>
        <p>'s active subscriptions</p>
      </div>
      {filteredSubscribers.length > 0 ? (
        <Grid>
          {filteredSubscribers.map((s) => {
            const formattedRenewDate = format(s.renewalDate, 'yyyy-MM-dd');
            const formattedStartDate = format(s.startDate, 'yyyy-MM-dd');
            const bodyText = s.autoRenew
              ? `Subscription will renew on ${formattedRenewDate}`
              : `Subscription will end on ${formattedRenewDate}`;
            return s.user ? (
              <UserCard
                key={s.id}
                user={s.user}
                body={() => (
                  <div className='flex flex-col mb-3 mt-1 text-center text-xs'>
                    <p className='mb-1'>{`$${(s.price / 100).toFixed(2)} - Cycle started ${formattedStartDate}`}</p>
                    <p className='mb-1 font-semibold'>{bodyText}</p>
                  </div>
                )}
              />
            ) : (
              <div className='w-[330px] md:w-[260px] h-[330px] md:h-[260px] flex flex-col justify-center items-center px-4 pt-2 pb-4 border rounded-lg shadow'>
                <p>User not found.</p>
                <p>Subscriber ID:</p>
                <code className='text-xs'>{s.id}</code>
              </div>
            );
          })}
        </Grid>
      ) : (
        <p>No subscribers yet.</p>
      )}
    </>
  );
}
