import { useLayoutEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FaCircleCheck, FaCircleXmark } from 'react-icons/fa6';
import BackButton from '../components/BackButton';
import Logo from '../components/Logo';
import { Colors } from '../constants/Colors';
import { useShoppingCart } from '../contexts/ShoppingCart';
import { APP_LINK } from '../constants/AppLink';
import {
  useGetProfileShareLinkQuery,
  useGetStripeCheckoutSessionDetailsQuery,
} from '../redux/api/api';

export default function Order() {
  const [searchParams] = useSearchParams();
  const { cart, clearCart } = useShoppingCart();
  const sessionId = searchParams.get('sessionId');

  const checkoutSessionDetailsQuery = useGetStripeCheckoutSessionDetailsQuery(
    sessionId as string,
  );
  const profileShareLinkQuery = useGetProfileShareLinkQuery(
    (cart?.capperUserId as string) ?? '',
  );

  useLayoutEffect(() => {
    if (profileShareLinkQuery.data?.link) {
      clearCart();
    }
  }, [profileShareLinkQuery.data?.link, clearCart]);

  const successCard = useMemo(() => {
    return (
      <div className='flex flex-col mt-10 w-96 rounded-lg border p-5 shadow justify-center items-center'>
        <FaCircleCheck size={48} color={Colors.successGreen} />
        <p className='text-xl font-bold my-4'>Thanks for your order!</p>
        <p className='text-lg text-center mb-4'>
          Your order was made successfully. Navigate to the app to access your
          subscription.
        </p>
        {profileShareLinkQuery.data?.link ? (
          <a className='w-1/2' href={profileShareLinkQuery.data.link}>
            <div className='rounded-lg p-4 bg-primaryBlue'>
              <p className='text-center text-white font-light text-sm'>
                Open the App
              </p>
            </div>
          </a>
        ) : (
          <a className='w-1/2' href={APP_LINK}>
            <div className='rounded-lg p-4 bg-primaryBlue'>
              <p className='text-center text-white font-light text-sm'>
                Open the App
              </p>
            </div>
          </a>
        )}
      </div>
    );
  }, [profileShareLinkQuery.data?.link]);

  const errorCard = useMemo(() => {
    return (
      <div className='flex flex-col mt-10 w-96 rounded-lg border p-5 shadow justify-center items-center'>
        <FaCircleXmark size={48} color={Colors.errorRed} />
        <p className='text-xl font-bold my-4'>Something went wrong</p>
        <p className='text-lg text-center mb-4'>
          Your order was not successful.
        </p>
        <a className='w-1/2' href={APP_LINK}>
          <div className='rounded-lg p-4 bg-primaryBlue'>
            <p className='text-white text-center font-light text-sm'>
              Open the App
            </p>
          </div>
        </a>
      </div>
    );
  }, []);

  return (
    <>
      <div className='m-3'>
        <BackButton to={'/'} />
      </div>
      <div className='flex flex-col justify-center items-center'>
        <Logo size={100} text={false} />
        {checkoutSessionDetailsQuery.data?.status ? (
          checkoutSessionDetailsQuery.data?.status === 'complete' ? (
            successCard
          ) : (
            errorCard
          )
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
