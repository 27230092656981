import { useNavigate } from 'react-router-dom';
import { btbApi, useGetUserAuthenticatedQuery } from '../../redux/api/api';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setUser, unsetUser } from '../../redux/slices/user.slice';
import { trackEvent } from '../../util/datadog';
import AvatarCircle from '../AvatarCircle';
import { useEffect } from 'react';

export default function LogoutButton() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id, displayName, username } = useAppSelector((state) => state.user);
  const { data } = useGetUserAuthenticatedQuery(id as string);

  useEffect(() => {
    if (data) {
      dispatch(setUser(data));
    }
  }, [data, dispatch]);

  if (!displayName || !username) {
    return <></>;
  }

  return (
    <>
      <div className='px-8 flex flex-row w-full'>
        <AvatarCircle userId={id as string} size={36} />
        <div className='ml-4 flex flex-col justify-center items-start'>
          <p className='text-ellipsis truncate text-black'>{displayName}</p>
          <p className='text-ellipsis truncate'>@{username}</p>
        </div>
      </div>
      <button
        className='w-4/5 flex flex-col justify-center items-center bg-primaryBlue hover:bg-hoverPrimaryBlue text-white p-2 rounded-md mt-1'
        onClick={() => {
          dispatch(unsetUser());
          dispatch(btbApi.util.resetApiState());
          trackEvent('logout-success');
          navigate('/');
        }}
      >
        <p>Log out</p>
      </button>
    </>
  );
}
