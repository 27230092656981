import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useShoppingCart } from '../contexts/ShoppingCart';
import CheckoutLayout from '../components/CheckoutLayout/CheckoutLayout';
import PhoneVerification from '../screens/PhoneVerification';
import PasswordVerification from '../screens/PasswordVerification';

export default function Authenticate() {
  const { cart } = useShoppingCart();
  const navigate = useNavigate();
  const { state } = useLocation();

  const [authPage, setAuthPage] = useState('phone');
  const [errorMessage] = useState<string | null>(state?.error ?? null);

  function handleNavigation(hasRegistered: boolean) {
    if (!hasRegistered) {
      navigate('/register');
    } else if (cart) {
      navigate('/checkout');
    } else {
      navigate('/');
    }
  }

  return (
    <div className='flex justify-center items-center w-screen h-screen'>
      <CheckoutLayout
        tabComponent={() => {
          return (
            <div className='flex flex-col items-center text-white text-lg mt-10 px-5'>
              <p>Log in to Beat the Book</p>
            </div>
          );
        }}
      >
        <div className='flex flex-col w-full h-full justify-center items-center'>
          {authPage === 'phone' ? (
            <>
              {errorMessage ? (
                <p className='mb-4 text-errorRed text-center'>{errorMessage}</p>
              ) : (
                <></>
              )}
              <PhoneVerification onSuccess={handleNavigation} />
              <button onClick={() => setAuthPage('password')}>
                <p className='mt-4 text-primaryBlue underline'>
                  Use Organization Login
                </p>
              </button>
            </>
          ) : (
            <>
              <PasswordVerification onSuccess={handleNavigation} />
              <button onClick={() => setAuthPage('phone')}>
                <p className='mt-4 text-primaryBlue underline'>
                  Return to One-Tap Login
                </p>
              </button>
            </>
          )}
        </div>
      </CheckoutLayout>
    </div>
  );
}
