import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { StripePrice } from '../models/SportscapperPackage';

export interface ShoppingCartState {
  addItemToCart: (item: StripePrice) => void;
  clearCart: () => void;
  cart: StripePrice | null;
}

const initial = {
  addItemToCart: (_item: StripePrice) => {},
  clearCart: () => {},
  cart: null,
};

const ShoppingCartContext = createContext<ShoppingCartState>(initial);

export function ShoppingCartProvider({ children }: PropsWithChildren) {
  const [cart, setCart] = useState<StripePrice | null>(
    JSON.parse(sessionStorage.getItem('cart') ?? 'null') ?? null,
  );

  const addItemToCart = (item: StripePrice) => {
    setCart(item);
    sessionStorage.setItem('cart', JSON.stringify(item));
  };

  const clearCart = () => {
    setCart(null);
    sessionStorage.removeItem('cart');
  };

  return (
    <ShoppingCartContext.Provider
      value={{
        cart,
        addItemToCart,
        clearCart,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
}

export function useShoppingCart() {
  return useContext(ShoppingCartContext);
}
