import {
  ChangeEvent,
  KeyboardEventHandler,
  createRef,
  useMemo,
  useState,
} from 'react';

export default function CodeInput({
  valueState,
}: {
  valueState: [string, React.Dispatch<React.SetStateAction<string>>];
}) {
  const CODE_LENGTH = new Array(6).fill(0);

  const [value, setValue] = valueState;

  const [focused, setFocused] = useState(false);

  const values = useMemo(() => {
    return value.split('');
  }, [value]);

  const input = createRef<HTMLInputElement>();

  const handleClick = () => {
    input.current?.focus();
  };

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const digit = e.target.value;

    setValue((prev) => {
      if (prev.length >= CODE_LENGTH.length || !/^\d+$/.test(digit))
        return prev;
      return (prev + digit).slice(0, CODE_LENGTH.length);
    });
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Backspace') {
      setValue((prev) => prev.slice(0, prev.length - 1));
    }
  };

  const selectedIndex = useMemo(() => {
    return values.length < CODE_LENGTH.length
      ? values.length
      : CODE_LENGTH.length - 1;
  }, [values, CODE_LENGTH]);

  const hideInput = useMemo(() => {
    return !(values.length < CODE_LENGTH.length);
  }, [values, CODE_LENGTH]);

  return (
    <div
      className='border inline-block relative flex flex-row rounded-lg bg-slate-100'
      onClick={handleClick}
    >
      <input
        value=''
        ref={input}
        onChange={handleChange}
        onKeyUp={handleKeyUp as unknown as KeyboardEventHandler}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className='absolute border-none text-[32px] text-center bg-transparent outline-none'
        style={{
          width: '32px',
          top: '6px',
          bottom: '0px',
          left: `${selectedIndex * 32}px`,
          opacity: hideInput ? 0 : 1,
        }}
      />
      {CODE_LENGTH.map((_, index) => {
        const selected = values.length === index;
        const filled =
          values.length === CODE_LENGTH.length &&
          index === CODE_LENGTH.length - 1;

        return (
          <div
            key={index}
            className='absolute border-r last:border-none w-[32px] h-[58px] flex items-center justify-center text-[24px] relative'
          >
            {values[index]}
            {(selected || filled) && focused && (
              <div
                className={`absolute top-0 left-0 right-0 bottom-0 border border-primaryBlue ${index === 0 ? 'rounded-l-lg' : index === CODE_LENGTH.length - 1 ? 'rounded-r-lg' : ''}`}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
