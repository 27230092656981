import NavigationLink from './NavigationLink';
import { IoHomeOutline } from 'react-icons/io5';
import { FiUser, FiUsers } from 'react-icons/fi';
import { BsBuildingLock } from 'react-icons/bs';
import { useEffect, useMemo, useState } from 'react';
import Logo from './Logo';
import { UserRole } from '../models/User';
import { HiOutlineBanknotes } from 'react-icons/hi2';
import { useLocation } from 'react-router-dom';
import LoginManager from './Login/LoginManager';
import { useAppSelector } from '../redux/hooks';

export default function NavigationDrawer() {
  const location = useLocation();

  const [selectedPage, setSelectedPage] = useState(location.pathname);

  const { id, userRoles } = useAppSelector((state) => state.user);

  const showPackageManager = useMemo(
    () => userRoles?.includes(UserRole.handicapper),
    [userRoles],
  );

  const showAdministratorPage = useMemo(
    () => userRoles?.includes(UserRole.admin),
    [userRoles],
  );

  useEffect(() => {
    setSelectedPage(location.pathname);
  }, [location.pathname]);

  const showProfilePage = useMemo(() => id !== undefined, [id]);

  return (
    <>
      <Logo />
      <div>
        <NavigationLink
          icon={<IoHomeOutline size={20} />}
          route={'/'}
          name={'Home'}
          selected={selectedPage === '/'}
          setSelectedPage={setSelectedPage}
        />
        <NavigationLink
          icon={<FiUsers size={20} />}
          route={'/subscriptions'}
          name={'Subscriptions'}
          selected={selectedPage === '/subscriptions'}
          setSelectedPage={setSelectedPage}
        />
        {showProfilePage && (
          <NavigationLink
            icon={<FiUser size={20} />}
            route={'/profile'}
            name={'Profile'}
            selected={selectedPage === '/profile'}
            setSelectedPage={setSelectedPage}
          />
        )}
        {showPackageManager && (
          <NavigationLink
            icon={<HiOutlineBanknotes size={20} />}
            route={'/manage'}
            name={'Manage Packages'}
            selected={selectedPage.includes('/manage')}
            setSelectedPage={setSelectedPage}
          />
        )}
        {showAdministratorPage && (
          <NavigationLink
            icon={<BsBuildingLock size={20} />}
            route={'/admin'}
            name={'Administrator'}
            selected={selectedPage === '/admin'}
            setSelectedPage={setSelectedPage}
          />
        )}
      </div>
      <LoginManager />
    </>
  );
}
