import { PropsWithChildren } from 'react';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import BackButton from '../BackButton';

export default function CheckoutLayout({
  children,
  tabComponent,
}: PropsWithChildren & {
  tabComponent: () => JSX.Element;
}) {
  const { width } = useWindowDimensions();

  return (
    <div className='z-0 flex flex-col md:flex-row h-screen w-screen'>
      <div className={`h-24 md:w-1/3 md:h-full`} />
      <div className='flex flex-col flex-1 items-center'>{children}</div>

      <div className='z-1 fixed bg-primaryBlue flex flex-col px-3 p-1 md:p-3 w-full h-1/8 md:w-1/3 md:h-screen shadow-lg'>
        <BackButton light={true} to={'/'} />
        {width >= 768 && tabComponent()}
      </div>
    </div>
  );
}
