import { Circles } from 'react-loader-spinner';
import { Colors } from '../constants/Colors';
import { useGetSubscribersAdminQuery } from '../redux/api/api';
import { GrUserAdmin } from 'react-icons/gr';
import SubscriberGrid from '../components/SubscriberGrid';
import { useAppSelector } from '../redux/hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function AdminDashboard() {
  const { id } = useAppSelector((state) => state.user);
  const { data, isLoading } = useGetSubscribersAdminQuery();
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
      navigate('/');
    }
  }, [id, navigate]);

  return (
    <>
      <div className='flex flex-col mx-3 mb-3'>
        <div className='fixed h-[45px] bg-white w-full'>
          <div className='flex flex-row items-center'>
            <div className='my-3 mr-3'>
              <GrUserAdmin className='ml-1' size={24} />
            </div>
            <p>All Platform Subscriptions</p>
          </div>
        </div>
        <div className='h-[45px]' />
        <div className={'flex flex-col items-center md:items-start'}>
          {isLoading ? (
            <div className='h-full w-3/4 flex flex-col justify-start items-center mt-20'>
              <Circles height={80} color={Colors.primaryBlue} width={80} />
            </div>
          ) : (
            data?.map(({ capper, subscriptions }) => (
              <SubscriberGrid
                key={capper.id}
                capper={capper}
                subscribers={subscriptions}
                admin={true}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
}
