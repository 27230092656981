import { useEffect, useState } from 'react';
import { Circles } from 'react-loader-spinner';
import { Colors } from '../constants/Colors';
import CodeInput from '../components/CodeInput';
import PhoneInput, {
  isValidPhoneNumber,
  formatPhoneNumberIntl,
} from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import flags from 'react-phone-number-input/flags';
import { IoMdClose } from 'react-icons/io';
import {
  useVerifyPhoneNumberCodeMutation,
  useVerifyPhoneNumberRequestMutation,
} from '../redux/api/api';

export default function PhoneVerification({
  onSuccess,
}: {
  onSuccess: (hasRegistered: boolean) => void;
}) {
  const [phoneMutate] = useVerifyPhoneNumberRequestMutation();
  const [codeMutate, codeMutationResult] = useVerifyPhoneNumberCodeMutation();

  const [e164PhoneNumber, setE164PhoneNumber] = useState<any>('');

  const [showVerifyModal, setShowVerifyModal] = useState(false);

  const [requestErrorMessage, setRequestErrorMessage] = useState('');
  const [modalErrorMessage, setModalErrorMessage] = useState('');
  const [phoneButtonDisabled, setPhoneButtonDisabled] = useState(false);

  const [code, setCode] = useState('');

  const submitPhoneNumber = async () => {
    setRequestErrorMessage('');
    let valid = true;
    try {
      phoneMutate(e164PhoneNumber.toString());
    } catch (err) {
      valid = false;
    }

    if (valid) {
      setShowVerifyModal(true);
    } else {
      setRequestErrorMessage(
        'Error sending phone number verification request. Please try again later.',
      );
    }
  };

  const submitCode = async () => {
    let valid = true;
    let hasRegistered = true;
    setModalErrorMessage('');
    try {
      const data = await codeMutate({
        phoneNumber: e164PhoneNumber.toString(),
        code,
      }).unwrap();
      hasRegistered = data?.userHasRegistered as boolean;
    } catch (err) {
      valid = false;
    }

    if (valid) {
      onSuccess(hasRegistered);
    } else {
      setModalErrorMessage(
        'The code you entered was invalid. Please enter the correct code.',
      );
    }
  };

  useEffect(() => {
    if (code.length === 6 && !codeMutationResult.isLoading) {
      submitCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <>
      <div className='flex flex-col justify-center items-start px-4 w-full md:w-4/5'>
        <b>Verify Phone Number</b>
        <PhoneInput
          onChange={setE164PhoneNumber}
          defaultCountry={'US'}
          international={true}
          className='my-3 border rounded-md p-4 w-full'
          placeholder={'Enter phone number'}
          value={e164PhoneNumber}
          flags={flags}
        />
        {requestErrorMessage ? (
          <p className='mb-4 text-center text-sm text-errorRed'>
            {requestErrorMessage}
          </p>
        ) : (
          <></>
        )}
        <p className='mt-2 mb-4 italic text-sm text-slate-600'>
          By entering your phone number and clicking verify, you opt in to
          receiving text messages for login.
        </p>
        <button
          disabled={
            (typeof e164PhoneNumber !== 'string'
              ? false
              : !isValidPhoneNumber(e164PhoneNumber)) || phoneButtonDisabled
          }
          className='disabled:opacity-75 disabled:hover:bg-gray-200 disabled:bg-gray-200 bg-primaryGold hover:bg-hoverPrimaryGold rounded-md p-4 disabled:text-gray-500 text-white w-full flex justify-center items-center'
          onClick={() => {
            setPhoneButtonDisabled(true);
            submitPhoneNumber();
          }}
        >
          <p>Verify</p>
        </button>
      </div>

      {showVerifyModal && (
        <div className='fixed top-0 left-0 w-screen h-screen bg-black/20 flex-1 flex justify-center items-center z-10'>
          <div className='bg-white w-9/10 md:w-fit rounded-lg shadow flex flex-col justify-start items-center py-5 px-3 md:py-5 md:px-7'>
            <div className='flex flex-row w-full'>
              <div className='flex-1' />
              <button
                onClick={() => {
                  setShowVerifyModal(false);
                  setPhoneButtonDisabled(false);
                  setModalErrorMessage('');
                }}
              >
                <IoMdClose size={20} />
              </button>
            </div>
            <p className='text-center text-lg font-bold'>
              Verify your Phone Number
            </p>
            <p className='text-center text-md mt-1'>
              Please enter the verification code sent to
            </p>
            <p className='text-center text-primaryBlue font-semibold mt-1'>
              {formatPhoneNumberIntl(e164PhoneNumber)}
            </p>
            <div className='h-3' />
            {codeMutationResult.isLoading ? (
              <Circles color={Colors.primaryBlue} height={72} />
            ) : (
              <>
                <CodeInput valueState={[code, setCode]} />
                {modalErrorMessage ? (
                  <p className='mt-4 text-center text-sm text-errorRed'>
                    {modalErrorMessage}
                  </p>
                ) : (
                  <></>
                )}
                <div className='h-3' />
                <div className='flex flex-row'>
                  <button
                    disabled={code.length !== 6}
                    onClick={() => submitCode()}
                    className='disabled:bg-primaryBlue/75 bg-primaryBlue p-3 rounded-md'
                  >
                    <p className='text-white font-light'>Verify</p>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
