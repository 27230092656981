import { FiUser } from 'react-icons/fi';
import { useAppSelector } from '../redux/hooks';
import {
  useGetUserAuthenticatedQuery,
  usePatchUserMutation,
} from '../redux/api/api';
import { SelfDisplayUser, UserRole } from '../models/User';
import { useCallback, useMemo, useState } from 'react';
import { LinkDiscordButton } from '../components/LinkDiscordButton';
import { Link } from 'react-router-dom';

export default function Profile() {
  const { id, userRoles } = useAppSelector((state) => state.user);
  const { data, isLoading, refetch } = useGetUserAuthenticatedQuery(
    id as string,
  );
  const [mutate, { isLoading: patchIsLoading }] = usePatchUserMutation();

  const [newEmail, setNewEmail] = useState('');
  const [emailError, setEmailError] = useState<string | null>(null);

  const handleEmailUpdate = useCallback(() => {
    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(newEmail.toLowerCase())) {
      setEmailError('Must provide a valid email.');
      return;
    }

    try {
      mutate({ userId: id as string, body: { email: newEmail } });
      refetch();
      setNewEmail('');
    } catch (err) {
      setEmailError(
        'There was an error updating your email. Please try again later.',
      );
    }
  }, [id, mutate, newEmail, refetch]);

  const patchBlock = useMemo(() => {
    return (
      <div className='flex flex-col justify-start items-start'>
        <div className='flex flex-row items-center justify-center px-2'>
          <div className='my-4 rounded-full'>
            <img
              className='rounded-full'
              src={data?.avatar.contentUrl}
              alt=''
              width={90}
            />
          </div>
          <div className='flex flex-col m-4 w-fit h-fit'>
            <p className='text-lg font-semibold tracking-wide'>
              {data?.displayName}
            </p>
            <p className='-mt-1 text-md tracking-tight text-primaryBlue'>
              @{data?.username}
            </p>
          </div>
        </div>

        <p className='font-bold mt-2 mb-1'>Email</p>
        <div className='flex flex-row justify-center items-center py-1 w-full md:w-4/5'>
          <input
            className='border rounded-md p-2 bg-slate-100 w-full'
            placeholder={(data as SelfDisplayUser)?.email}
            onChange={(e) => {
              setNewEmail(e.target.value);
              setEmailError(null);
            }}
            value={newEmail}
          />
          <button
            className='ml-2'
            onClick={handleEmailUpdate}
            disabled={patchIsLoading}
          >
            <p className='text-nowrap border p-2 bg-primaryBlue rounded-md text-white disabled:bg-slate-200'>
              Update Email
            </p>
          </button>
        </div>
        {emailError && <p className='text-errorRed text-sm'>{emailError}</p>}

        <p className='mt-2 mb-1 font-bold'>Link Discord Account</p>
        {userRoles?.includes(UserRole.handicapper) &&
          (data as SelfDisplayUser)?.discordId && (
            <Link className='text-primaryBlue underline' to={`/manage/discord`}>
              Read about Beat the Book's Discord Bot for managing your
              subscribers!
            </Link>
          )}
        {(data as SelfDisplayUser)?.discordId && (
          <p className='mb-2'>Discord account linked! Update linked account:</p>
        )}
        <LinkDiscordButton />
      </div>
    );
  }, [
    data,
    emailError,
    handleEmailUpdate,
    newEmail,
    patchIsLoading,
    userRoles,
  ]);

  return (
    <>
      <div className='flex flex-col mx-3 mb-3'>
        <div className='fixed h-[45px] bg-white w-full'>
          <div className='flex flex-row items-center'>
            <div className='my-3 mr-3'>
              <FiUser size={24} />
            </div>
            <p>View and Edit Profile</p>
          </div>
        </div>
        <div className='h-[45px]' />

        {isLoading ? <></> : patchBlock}
      </div>
    </>
  );
}
