import { Content } from './Content';

export type DisplayUser = {
  id: string;
  username: string;
  displayName: string;
  verified: boolean;
  deleted: boolean;
  stripeAccountId?: string;
  avatar: Content;
};

export type DisplayUserWithPackage = {
  lowestPriceInCents: number;
} & DisplayUser;

export enum UserRole {
  base = 'base',
  admin = 'admin',
  handicapper = 'handicapper',
}

export type User = {
  id: string;
  username: string;
  displayName: string;
  verified: boolean;
  deleted: boolean;
  avatar: Content;
};

export type SelfDisplayUser = {
  id: string;
  username: string;
  displayName: string;
  verified: boolean;
  deleted: boolean;
  stripeAccountId?: string;
  email: string;
  discordId: string;
  avatar: Content;
};

export type DisplayUserWithSubscriptionInfo = {
  id: string;
  startDate: Date;
  renewalDate: Date;
  autoRenew: boolean;
  price: number;
  user: DisplayUserWithPackage;
};

export type PatchUserRequest = {
  email?: string;
  discordId?: string;
};

export type DiscordUser = {
  id: number;
  username: string;
  avatar: string;
  discriminator: number;
  public_flags: number;
  flags: number;
  banner: string | null;
  accent_color: string | null;
  global_name: string | null;
  avatar_decoration_data: string | null;
  banner_color: string | null;
  clan: string | null;
  mfa_enabled: boolean;
  locale: string;
  premium_type: number;
};
