import { datadogLogs } from '@datadog/browser-logs';

datadogLogs.init({
  site: 'datadoghq.com',
  env: process.env.REACT_APP_ENV,
  service: 'btb-sportscapper-portal',
  version: process.env.REACT_APP_VERSION,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  clientToken: process.env.REACT_APP_DD_CLIENT_TOKEN as string,
});

// event names should be in the form `<verb>-<name>-<modifier?>`
// - see https://beatthebookinc.slack.com/archives/C05QPBJGS5S/p1706645779296169
export function trackEvent(eventName: string, context?: object) {
  datadogLogs.logger.info(`[event] ${eventName}`, {
    location: window.location,
    ...context,
  });
}
