import { useEffect, useState } from 'react';
import { useCreateExpressAccountMutation } from '../redux/api/api';
import { trackEvent } from '../util/datadog';
import { Circles } from 'react-loader-spinner';
import { Colors } from '../constants/Colors';

export default function ManageSportscapperOnboarding() {
  const [mutate, { isLoading }] = useCreateExpressAccountMutation();
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    if (url) {
      if (!window.open(url)) {
        window.location.href = url;
      }
    }
  }, [url]);

  return (
    <div className='mt-4 flex flex-col justify-center items-start w-full'>
      <button
        disabled={isLoading || !!url}
        className='bg-primaryBlue p-4 rounded-md text-white disabled:bg-slate-400 w-2/5 flex flex-row items-center justify-center'
        onClick={() => {
          trackEvent('onboard-sportscapper');
          const fetchUrl = async () => {
            const res = await mutate().unwrap();
            setUrl(res.url);
          };

          fetchUrl();
        }}
      >
        {isLoading || url ? (
          <Circles color={Colors.white} width={20} height={20} />
        ) : (
          <p>Create Stripe account to access the rest of the dashboard</p>
        )}
      </button>
    </div>
  );
}
