import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import userReducer from './slices/user.slice';
import { btbApi } from './api/api';

const logger = createLogger({
  // to show the difference between what changed in state
  diff: true,

  // to log time
  duration: true,
  timestamp: true,

  // custom colors for each log
  colors: {
    title: () => '#FFFFFF',
    prevState: () => '#de6f0d',
    action: () => '#6e13ab',
    nextState: () => '#1a9134',
  },
});

export const store = configureStore({
  reducer: {
    user: userReducer,
    [btbApi.reducerPath]: btbApi.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware().concat(btbApi.middleware);
    if (process.env.NODE_ENV === 'production') {
      return middleware;
    }
    return middleware.concat(logger);
  },
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
